import { Button } from "../../../components";
import {
  MediaPurpose,
  MediaType,
  Vendor,
  VendorMediaPurpose,
} from "../../../hooks/api/types";
import countries from "world-countries";
import { truncate } from "../../../utils";
import AttachedDocumentCard from "../../LoanApplicationSteps/Review/sections/AttachedDocumentCard";
import React, { FC, useEffect, useState } from "react";
import { FileObject } from "../../LoanApplicationSteps/BusinessInformation/service";
import { useMedia, useUpdateLoanVendorDocumentRequest } from "../../../hooks";
import { WarningIcon } from "../../../assets/icons";
import { CustomFile } from "../../LoanApplicationSteps/FinancialInformation/service";
import AddVendorBankStatementModal from "./AddVendorBankStatementModal";

export type VendorBankStatementSectionProps = {
  vendors?: Array<Vendor>;
  loanApplicationId: string;
  onSuccess?: () => void;
};

const VendorBankStatementSection: FC<VendorBankStatementSectionProps> = ({
  vendors,
  loanApplicationId,
  onSuccess,
}) => {
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const [bankStatementsUploading, setBankStatementsUploading] = useState<
    boolean[]
  >([]);
  const [stagedBankStatements, setStagedBankStatements] = useState<
    Array<FileObject>
  >([]);

  const { upload, get } = useMedia();

  const { mutateAsync: updateLoanVendorDocumentRequest } =
    useUpdateLoanVendorDocumentRequest();

  const bootstrapFilesState = async () => {
    //get all documents from documents inside loanVendorDocumentRequests inside vendors where BANK_STATEMENT is the type
    const docs = vendors?.map((vendor) => {
      return vendor.loanVendorDocumentRequests?.map((request) => {
        if (request.type === VendorMediaPurpose.BANK_STATEMENT) {
          return request.loanVendorDocuments.map((doc) => {
            return doc.documentId;
          });
        }
      });
    });

    //flatten docs array
    const flatDocs = docs?.flat(2);
    // convert flatDocs into set
    const uniqueDocs = new Set<string | undefined>(flatDocs);

    const mediaFiles = await Promise.all(
      Array.from(uniqueDocs).map(async (doc) => {
        if (doc) {
          const mediaRes = await get({ id: doc });
          const file = new CustomFile(
            new File([], mediaRes.media.fileName),
            mediaRes.media.id
          );
          Object.defineProperty(file, "size", {
            value: mediaRes.media.size,
          });
          return { id: mediaRes.media.id, file, uploaded: true };
        }
      })
    );
    //filter for only truthy values
    const filteredMediaFiles = mediaFiles.filter((file) => file !== undefined);

    setStagedBankStatements(filteredMediaFiles as Array<FileObject>);
  };

  useEffect(() => {
    if (vendors?.length) {
      bootstrapFilesState();
    }
  }, [vendors]);

  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mb-6">
      <div className="cx-flex cx-flex-row cx-justify-between">
        <div className={"cx-pr-5"}>
          <div className={"cx-text-2xl cx-font-bold cx-mb-1"}>
            Verified Bank statements
          </div>
          <div
            className={
              "cx-text-s cx-text-text-secondary cx-font-normal cx-mb-6"
            }
          >
            There are some missing documents that we require to proceed with
            your suppliers. Please add your documents.
          </div>
        </div>
        <div className={"cx-text-s "}>
          <Button
            label={"+ Add bank statements"}
            slim={true}
            className={"cx-h-8"}
            onClick={() => {
              setAddDocumentModalOpen(true);
            }}
          />
        </div>
      </div>
      {Boolean(vendors?.length) && (
        <div className="cx-mt-6">
          <div className="cx-text-base cx-font-semibold">Bank statements</div>
          <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
            {vendors?.map((vendor: Vendor) => (
              <div
                className={`cx-flex cx-flex-row cx-items-center cx-justify-between cx-w-[428px] cx-max-h-[85px] cx-p-4 cx-border cx-border-stroke-primary cx-rounded-base cx-mb-4 `}
                key={vendor.id}
              >
                <div
                  className={
                    "cx-flex cx-flex-row cx-items-center cx-content-center cx-w-full"
                  }
                >
                  <div className={"cx-font-bold cx-text-2xl"}>
                    {
                      countries.find(
                        (country) => country.cca3 === vendor.country
                      )?.flag
                    }
                  </div>
                  <div
                    className={
                      "cx-flex cx-flex-row cx-w-full cx-ml-4 cx-justify-between"
                    }
                  >
                    <div className={"cx-font-medium cx-text-base"}>
                      {truncate(vendor.name, 40)}
                    </div>
                    <WarningIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="cx-mb-6 cx-mt-4 cx-flex cx-flex-row cx-flex-wrap cx-gap-4">
        {Boolean(stagedBankStatements?.length) &&
          stagedBankStatements.map(
            (doc, idx) =>
              doc && (
                <AttachedDocumentCard
                  key={doc.id}
                  document={{ id: doc.id }}
                  loading={bankStatementsUploading[idx]}
                  showTitle={true}
                  onChange={async (file) => {
                    if (!file) return;
                    setBankStatementsUploading((prev) => {
                      const newPrev = [...prev];
                      newPrev[idx] = true;
                      return newPrev;
                    });
                    const id = await upload({
                      file,
                      type: MediaType.DOCUMENT,
                      purpose: MediaPurpose.BANK_STATEMENT,
                      loanApplicationId: loanApplicationId,
                    });
                    setStagedBankStatements((prev) => {
                      const newPrev = [...prev];
                      newPrev[idx].id = id;
                      return newPrev;
                    });
                    setBankStatementsUploading((prev) => {
                      const newPrev = [...prev];
                      newPrev[idx] = false;
                      return newPrev;
                    });
                  }}
                />
              )
          )}
      </div>
      <AddVendorBankStatementModal
        isOpen={addDocumentModalOpen}
        onClose={() => {
          setAddDocumentModalOpen(false);
        }}
        loanApplicationId={loanApplicationId}
        documents={stagedBankStatements}
        setDocuments={setStagedBankStatements}
        vendors={vendors}
        onSuccess={async (docList) => {
          vendors?.map((vendor) => {
            vendor.loanVendorDocumentRequests?.map(async (request) => {
              if (request.type === VendorMediaPurpose.BANK_STATEMENT) {
                await updateLoanVendorDocumentRequest({
                  loanApplicationId: loanApplicationId,
                  loanVendorId: vendor.loanVendorId,
                  documentRequestId: request.id,
                  update: {
                    documentIds: docList,
                  },
                });
              }
            });
          });
          onSuccess?.();
        }}
      />
    </div>
  );
};

export default VendorBankStatementSection;
