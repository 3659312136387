import { FC } from "react";
import { Card, Divider } from "../../../components";
import {
  HyphenIcon,
  ShareholderCompanyIcon,
  ShareholderUserIcon,
} from "../../../assets/icons";

type Props = {
  mode?: "add" | "edit" | "selection";
  name?: string;
  email?: string;
  onClick?: () => void;
  onDelete?: () => void;
  className?: string;
  isPrimaryApplicant?: boolean;
  isPOAHolder?: boolean;
  isShareholder?: boolean;
  isCompanyShareholder?: boolean;
};

enum PillType {
  ORANGE = "orange",
  GREEN = "green",
}

interface PillProps {
  text: string;
  type: PillType;
}

const Pill = ({ text, type }: PillProps) => {
  const colorClass =
    type === PillType.ORANGE
      ? "cx-border-orange-600 cx-text-orange-600"
      : "cx-border-green-600 cx-text-green-600";

  const dotColorClass =
    type === PillType.ORANGE ? "cx-bg-orange-600" : "cx-bg-green-600";

  return (
    <div
      className={`cx-px-3 cx-py-1 cx-rounded-full cx-border cx-text-xs cx-flex cx-items-center ${colorClass}`}
    >
      <span
        className={`cx-inline-block cx-h-2 cx-w-2 cx-rounded-full cx-mr-2 ${dotColorClass}`}
      ></span>
      {text}
    </div>
  );
};

const ShareholderCard: FC<Props> = ({
  mode = "add",
  name,
  onClick,
  onDelete,
  email,
  className,
  isPrimaryApplicant = false,
  isPOAHolder = false,
  isShareholder = false,
  isCompanyShareholder = false,
}) => {
  if (mode === "edit") {
    return (
      <div className="cx-w-[348px] cx-max-w-[960px] cx-py-3 cx-relative">
        <div className="cx-w-full cx-rounded-base cx-shadow-base cx-bg-background-default cx-h-full cx-py-6">
          {isPrimaryApplicant && (
            <div className="cx-absolute cx-top-0 cx-left-4">
              <div className="cx-bg-green-lighter cx-border-green-light cx-border cx-text-green-darker cx-px-3 cx-py-1 cx-rounded-full cx-text-xs">
                <span className="cx-inline-block cx-h-2 cx-w-2 cx-bg-green-darker cx-rounded-full cx-mr-2"></span>
                Primary applicant
              </div>
            </div>
          )}
          <div className="cx-flex cx-justify-between cx-items-center cx-px-4">
            <div
              className="cx-flex cx-items-center cx-cursor-pointer"
              onClick={onClick}
            >
              <div className="cx-bg-gray-200 cx-h-12 cx-w-12 cx-rounded-full cx-flex cx-items-center cx-justify-center cx-mr-3">
                {isCompanyShareholder ? (
                  <ShareholderCompanyIcon className="cx-text-brand-primary-regular cx-w-7 cx-h-7" />
                ) : (
                  <ShareholderUserIcon className="cx-text-brand-primary-regular cx-w-7 cx-h-7" />
                )}
              </div>
              <div className="cx-flex cx-flex-col cx-text-left">
                <div className="cx-font-medium cx-text-lg cx-text-black">
                  {name}{" "}
                  {isPrimaryApplicant && (
                    <span className="cx-font-medium cx-text-lg cx-text-black">
                      (You)
                    </span>
                  )}
                </div>
                <div className="cx-text-sm cx-text-gray-600 cx-font-normal">
                  {email}
                </div>
              </div>
            </div>
            {!isPrimaryApplicant && (
              <button
                type="button"
                className="cx-w-6 cx-h-6 cx-rounded-full cx-flex cx-items-center cx-justify-center cx-cursor-pointer cx-text-red-500 cx-bg-error-lighter"
                onClick={onDelete}
              >
                <HyphenIcon />
              </button>
            )}
          </div>
          <Divider className="cx-my-4 cx-w-full" />
          <div className="cx-flex cx-justify-start cx-items-center cx-gap-2 cx-px-4">
            {isShareholder && (
              <Pill text="Shareholder" type={PillType.ORANGE} />
            )}
            {isPOAHolder && <Pill text="POA Holder" type={PillType.GREEN} />}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cx-w-[348px]">
      <Card className={`cx-h-full ${className} `}>
        {isPrimaryApplicant && (
          <div className="cx-absolute cx-top-0 cx-left-4">
            <div className="cx-bg-green-lighter cx-border-green-light cx-border cx-text-green-darker cx-px-3 cx-py-1 cx-rounded-full cx-text-xs">
              <span className="cx-inline-block cx-h-2 cx-w-2 cx-bg-green-darker cx-rounded-full cx-mr-2" />
              Primary applicant
            </div>
          </div>
        )}
        {!!onDelete && (
          <div
            className="cx-absolute cx-top-5 cx-right-2 cx-w-6 cx-h-6 cx-text-center cx-rounded-full cx-flex cx-items-center cx-justify-center cx-bg-error-regular cx-text-white cx-cursor-pointer"
            onClick={onDelete}
          >
            <HyphenIcon className="cx-w-4 cx-h-4 cx-text-white" />
          </div>
        )}
        <div
          className="cx-px-4 cx-text-interaction-button-text-default cx-flex cx-flex-col cx-items-center cx-gap-4 cx-min-w-[200px] cx-cursor-pointer cx-h-full cx-relative"
          onClick={onClick}
        >
          <div className="cx-bg-gray-200 cx-h-12 cx-w-12 cx-rounded-full cx-items-center cx-justify-center cx-flex">
            <ShareholderUserIcon className="cx-text-brand-primary-regular cx-w-7 cx-h-7" />
          </div>
          <p className="cx-text-center cx-grow cx-font-medium cx-text-base cx-text-brand-primary-regular">
            {name}
          </p>

          {mode === "selection" && (
            <p className="cx-text-center cx-text-text-secondary cx-text-sl cx-w-full cx-break-words cx-whitespace-normal">
              {email}
            </p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default ShareholderCard;
