import { LoanProductType } from "../../hooks/api/types";
import { UserLoanCalculatorConstraintsConfig } from "../LoanCalculator/types";

export type AdditionalDPMetadataFieldPopup = {
  label: string;
  value: string;
};
export type AdditionalDPMetadata = {
  key: string;
  displayName: string;
  regex: string;
  placeholder: string;
  popup?: AdditionalDPMetadataFieldPopup;
};

export type faq = {
  question: string;
  answer: string;
};

export type DistributionPartnerSettings = {
  illustrationScreens: boolean;
  hideHeader: boolean;
  hideFooter: boolean;
  hideLogo: boolean;
  disableWhatsappBubble: boolean;
  additionalData?: AdditionalDPMetadata[];
  hideLoanReasonsScreen: boolean;
  hideProductSelectionPage: boolean;
  hideStartLoanApplicationScreen: boolean;
  hideEligibilityScreen: boolean;
  disableLOCFlow: boolean;
  showRepaymentDisclaimer: boolean;
  disableLandingPage: boolean;
};

export type LoanProductConfigFlags = {
  dpReviewRequired?: boolean;
  repayableByDP?: boolean;
  showAnnualInterestOnOffer?: boolean;
  disablePaymentSchedule?: boolean;
  hidePlatformColumnOnPayments?: boolean;
  hideTransactionColumnOnPayments?: boolean;

  // flags moved from dp to dplp + lp
  directDebitRequired?: boolean;
  disableInvoiceUpload?: boolean;
  disableSalesInvoiceUpload?: boolean;
  hideInterestPaymentOnOffer?: boolean;
  disableSalesStatementsUpload?: boolean;
  disableBankStatementsUpload?: boolean;
  disableVATStatementsUpload?: boolean;
  disableRevenueHistory?: boolean;
  isPartnerTermsAccepted?: boolean;
  disbursementAccountProvided?: boolean;
  showMerchantIdStep?: boolean;
  enableVendorScreen?: boolean;
};

export type LoanProductInvoiceConfig = {
  discount: {
    min: number;
    max: number;
    initial: number;
    unit: string;
  };
};

export type LoanProductConfig = {
  amount: {
    min: number;
    max: number;
    initial: number;
    unit: string;
  };
  duration: {
    min: number;
    max: number;
    initial: number;
    unit: string;
    value?: number;
    isRange?: boolean;
  };
  interestRate: number;
} & LoanProductConfigFlags;

export type LoanProduct = {
  min: number;
  config:
    | LoanProductConfig
    | (LoanProductConfig & LoanProductInvoiceConfig)
    | (LoanProductConfig & {
        factorRates: Record<number, number>;
      });
  displayName: string;
  id: string;
  type: LoanProductType;
  uiLocalizationData?: LpUiLocalizationData;
  userConstraintsConfig?: UserLoanCalculatorConstraintsConfig;
};

export type FAQ = {
  question: string;
  answer: string;
};

export type UILocalizationData = {
  introPage: {
    headline: string;
    approvalBox: {
      title: string;
      subtitle: string;
    };
    applyBox: string;
    howItWorks: {
      title: string;
      subtitle: string;
      optional?: boolean;
    }[];
    requiredDocuments: {
      title: string;
      subtitle: string;
      optional?: boolean;
    }[];
  };
  loanCalculatorScreen: {
    faqs: FAQ[] | null;
  };
  invoicePage: {
    dropBoxLabel: string;
  };
  reviewScreen: {
    invoiceLabel: string;
  };
  loanOfferMicroflow: {
    usePartnerLogo: boolean;
    loanOfferStartPage: {
      pageHeading: string;
      pageDescription: string;
      approvedAmountLabel: string;
      hideMonthlyPayment: boolean;
      hideLoanType: boolean;
      totalInterestAmountLabel?: string;
    };
  };
  loanDashboard: {
    approvedTotalAmountLabel: string;
  };
  footer: {
    logoUrl: string;
    usePartnerLogo: boolean;
  };
};

export type LpUiLocalizationData = {
  reviewScreen: {
    monthlyPaymentLabel: string;
    monthlyInterestLabel: string;
    hideLoanMetricsDisclaimers: boolean;
    hidePayAsLowAs: boolean;
  };
  smeDashboard: {
    showPaymentProcessThroughDPText: boolean;
  };
};

export type DpConfigState = {
  settings: DistributionPartnerSettings;
  uiLocalizationData: UILocalizationData;
  dpTermsUrl: string;
  products: LoanProduct[];
  loading: boolean;
  name: string;
};

type SetSettingsReducerAction = {
  type: "SET_SETTINGS";
  payload: DistributionPartnerSettings;
};

type SetUiLocalizationDataReducerAction = {
  type: "SET_UI_LOCALIZATION_DATA";
  payload: UILocalizationData;
};

type SetDpTermsUrlReducerAction = {
  type: "SET_DP_TERMS_URL";
  payload: string;
};

type SetDpNameReducerAction = {
  type: "SET_DP_NAME";
  payload: string;
};

type SetProductsReducerAction = {
  type: "SET_PRODUCTS";
  payload: LoanProduct[];
};

type SetLoadingReducerAction = {
  type: "SET_LOADING";
  payload: boolean;
};
export type DpConfigReducerAction =
  | SetSettingsReducerAction
  | SetUiLocalizationDataReducerAction
  | SetDpTermsUrlReducerAction
  | SetDpNameReducerAction
  | SetProductsReducerAction
  | SetLoadingReducerAction;

export type DpConfigActions = {
  setLoanProducts: (products: LoanProduct[]) => void;
  setSettings: (settings: DistributionPartnerSettings) => void;
  setUiLocalizationData: (uiLocalizationData: UILocalizationData) => void;
  setDpTermsUrl: (dpTermsUrl: string) => void;
  setDpName: (dpName: string) => void;
  setLoading: (loading: boolean) => void;
};

export type DpConfigContextState = {
  state: DpConfigState;
  actions: DpConfigActions;
};

export const CONFIG_INITIAL_STATE: DpConfigContextState = {
  state: {
    settings: {
      illustrationScreens: true,
      hideHeader: false,
      hideFooter: false,
      hideLogo: false,
      disableWhatsappBubble: false,
      hideLoanReasonsScreen: false,
      hideProductSelectionPage: false,
      hideStartLoanApplicationScreen: false,
      hideEligibilityScreen: false,
      disableLOCFlow: false,
      showRepaymentDisclaimer: false,
      disableLandingPage: false,
    },
    uiLocalizationData: {
      introPage: {
        headline: "Loans to support your business, in building a success story",
        approvalBox: {
          title: "Fast pre-approval",
          subtitle: "Get loan status in 24 hours",
        },
        applyBox: "Apply For a Loan",
        howItWorks: [
          {
            title: "Customize your loan",
            subtitle:
              "Define your loan amount, and duration based on your needs",
          },
          {
            title: "Verify your business information",
            subtitle:
              "Provide your Trade License and key details about your company",
          },
          {
            title: "Verify the shareholders information",
            subtitle:
              "Provide Emirates ID, and personal details for all shareholders with more than 25% ownership",
          },
          {
            title: "Submit business revenue information",
            subtitle:
              "Connect your bank or provide bank statements, and VAT statements",
          },
          {
            title: "Sign loan offer",
            subtitle: "You will receive a loan offer from our team",
          },
        ],
        requiredDocuments: [
          {
            title: "Emirates ID",
            subtitle:
              "Required to verify the identity of the person applying for a loan",
          },
          {
            title: "Trade license",
            subtitle: "Required to verify the business information",
          },
          {
            title: "Memorandum of Association",
            subtitle:
              "Required to understand the business objectives and details during its formation",
          },
          {
            title: "Power of attorney",
            subtitle:
              "Required incase you are not the owner, but authorized to take out a loan on behalf of the company",
            optional: true,
          },
          {
            title: "Bank and VAT Statements",
            subtitle:
              "Required to verify the business revenue and tax information",
          },
        ],
      },
      loanCalculatorScreen: {
        faqs: [],
      },
      invoicePage: {
        dropBoxLabel: "Upload sales invoice",
      },
      reviewScreen: {
        invoiceLabel: "Sales Invoice",
      },
      loanOfferMicroflow: {
        usePartnerLogo: false,
        loanOfferStartPage: {
          pageHeading: "Your loan offer is ready",
          pageDescription: "Please review the loan details",
          approvedAmountLabel: "Approved loan limit",
          hideMonthlyPayment: false,
          hideLoanType: false,
        },
      },
      loanDashboard: {
        approvedTotalAmountLabel: "Total amount disbursed",
      },
      footer: {
        logoUrl: "",
        usePartnerLogo: false,
      },
    },
    dpTermsUrl: "",
    products: [],
    loading: false,
    name: "",
  },
  actions: {
    setLoanProducts: () => {},
    setSettings: () => {},
    setUiLocalizationData: () => {},
    setDpTermsUrl: () => {},
    setDpName: () => {},
    setLoading: () => {},
  },
};
