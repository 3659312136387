import { useEffect, useState } from "react";

export enum Variant {
  normal,
  light,
}

export type TabProps = {
  label: string;
  labelComponent?: React.ReactNode;
  component: React.ReactNode;
  onTabClicked?: () => void;
};

type Props = {
  startingTab: number;
  tabs: TabProps[];
  disabled?: boolean;
  variant?: Variant;
};

const Tabs = ({
  startingTab,
  tabs,
  disabled,
  variant = Variant.normal,
}: Props) => {
  const [currentTab, setCurrentTab] = useState(startingTab);

  useEffect(() => {
    if (currentTab !== startingTab) {
      setCurrentTab(startingTab);
    }
  }, [startingTab]);

  return (
    <div
      className={
        "cx-grow cx-flex cx-flex-col cx-content-center cx cx-items-center cx-w-full cx-max-w-[960px]"
      }
    >
      {variant === Variant.normal && (
        <div className="cx-flex cx-flex-row cx-rounded-lg cx-bg-neutral-lighter">
          {tabs.map((tab, idx) => {
            let tabStyle: string;
            if (currentTab === idx) {
              tabStyle = disabled
                ? "cx-bg-white cx-text-text-tertiary cx-cursor-not-allowed"
                : "cx-shadow-tab cx-cursor-pointer cx-bg-white";
            } else {
              tabStyle = disabled
                ? "cx-text-text-tertiary cx-cursor-not-allowed"
                : "cx-text-text-secondary cx-cursor-pointer";
            }
            return (
              <div
                key={idx}
                className={`${tabStyle} cx-flex cx-justify-center cx-self-stretch cx-items-center cx-grow cx-basis-0 cx-shrink-0 cx-text-center cx-m-1 cx-rounded-lg cx-p-2`}
                onClick={() => {
                  if (disabled) return;
                  setCurrentTab(idx);
                  if (tab.onTabClicked) {
                    tab.onTabClicked();
                  }
                }}
              >
                {tab.labelComponent && currentTab == idx
                  ? tab.labelComponent
                  : tab.label}
              </div>
            );
          })}
        </div>
      )}
      {variant === Variant.light && (
        <div className="cx-border-b cx-border-gray-200 cx-mb-4 cx-w-full">
          <div className="cx-flex cx-flex-row">
            {tabs.map((tab, index) => (
              <button
                key={tab.label}
                onClick={() => setCurrentTab(index)}
                className={`cx-py-2 cx-px-3 cx-text-lg cx-font-medium cx-w-32 cx-text-left ${
                  currentTab === index
                    ? "cx-text-text-brand cx-border-b-2 cx-border-brand-primary-regular"
                    : "cx-text-text-secondary hover:cx-text-text-tertiary"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>
      )}
      {tabs[currentTab]?.component}
    </div>
  );
};

export default Tabs;
