import { FC, useEffect, useState } from "react";
import { Button, Divider, InputField, Slider } from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { useFormik } from "formik";
import {
  getPosRevenueBasedLoanValidationSchema,
  LOAN_FIXED_TERM_INITIAL_VALUES,
  PosRevenueBasedLoanCalculatorFields,
} from "./service";
import {
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { roundTo } from "../../utils";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ArrowLeftIcon } from "../../assets/icons";
import { useLoanMetrics, useMedia } from "../../hooks";
import { useDpConfig } from "../../contexts/dpConfig";
import TermsAndConditions from "./TermsAndConditions";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const PosRevenueBasedLoanCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const [isPartnerTermsAccepted, setIsPartnerTermsAccepted] =
    useState<boolean>(false);
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const [sortedDurations, setSortedDurations] = useState<number[]>([]);

  const {
    state: { posRevenueBasedLoan, constraints },
    actions: { setPosRevenueBasedLoan },
  } = useLoanCalculator();

  const {
    state: { id, posSalesStatementFiles, sourceId, stage },
    actions: { update },
  } = useLoanApplication();
  const { nextStep } = useStepper();
  const { factorFeeForPosRevenueBasedFinancing } = useLoanMetrics();
  const { upload } = useMedia();
  const {
    state: {
      dpTermsUrl,
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const { enableVendorScreen } = useLoanProductConfigFlags(
    LoanProductType.POS_REVENUE_BASED_FINANCING
  );

  const { values, errors, setFieldValue, handleSubmit } =
    useFormik<PosRevenueBasedLoanCalculatorFields>({
      enableReinitialize: true,
      initialValues: {
        ...LOAN_FIXED_TERM_INITIAL_VALUES,
        loanAmount:
          posRevenueBasedLoan?.amount ||
          constraints.posRevenueBasedLoan?.amount?.initial,
        duration:
          posRevenueBasedLoan?.duration ||
          Number(Object.keys(constraints.posRevenueBasedLoan.factorRates)[0]),
      },
      validationSchema: getPosRevenueBasedLoanValidationSchema({
        loanAmount: {
          min: constraints.posRevenueBasedLoan?.amount?.min,
          max: constraints.posRevenueBasedLoan?.amount?.max,
          unit: constraints.posRevenueBasedLoan?.amount?.unit ?? "AED",
        },
        duration: {
          min: sortedDurations[0] || 0,
          max: sortedDurations[sortedDurations.length - 1] || 0,
        },
      }),
      onSubmit: async () => {
        if (!isPartnerTermsAccepted && dpTermsUrl) {
          setSubmissionError(true);
          return;
        }
        setPosRevenueBasedLoan({
          amount: values.loanAmount,
          duration: values.duration,
          factorRate:
            constraints.posRevenueBasedLoan.factorRates[values.duration],
          durationType: constraints.posRevenueBasedLoan.duration
            .unit as LoanDurationType,
          posSalesStatements: [],
        });

        //if legacy flow is enabled, we go to the next step
        if (disableLOCFlow) {
          nextStep();
          return;
        }
        await handlePosRevenueBasedLoanUpdate(id);
        let nextStage = LoanApplicationStage.OWNER_INFORMATION;
        if (sourceId) {
          nextStage = LoanApplicationStage.DOCUMENTS_UPLOAD;
        }

        await update({
          stage: nextStage,
        });
      },
    });

  async function handlePosRevenueBasedLoanUpdate(id: string) {
    const posSalesStatementPromises = posSalesStatementFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.POS_SALES_STATEMENT,
        loanApplicationId: id,
      })
    );
    const posSalesStatementIds = await Promise.all(posSalesStatementPromises);
    const posSalesStatements = posSalesStatementIds.map(
      (_statement, index) => ({
        posStatementId: posSalesStatementIds[index],
      })
    );
    await update(
      {
        id,
        loanType: LoanProductType.POS_REVENUE_BASED_FINANCING,
        posRevenueBasedLoan: {
          amount: values.loanAmount,
          duration: values.duration,
          factorRate:
            constraints.posRevenueBasedLoan.factorRates[values.duration],
          durationType: constraints.posRevenueBasedLoan.duration
            .unit as LoanDurationType,
          posSalesStatements: posSalesStatements,
        },
      },
      {
        local: disableLOCFlow,
      }
    );
  }

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }

    setFieldValue(name, num, true);

    //change here
    const validation: any = {
      loanAmount: constraints.posRevenueBasedLoan.amount,
      duration: constraints.posRevenueBasedLoan.duration,
    };

    if (num > validation[name].max || num < validation[name].min) {
      return null;
    }
    return num;
  };

  const handleLoanAmountInputChange = (value: string) => {
    const val = handleInputChange("loanAmount", value);
    if (val !== null) {
      setPosRevenueBasedLoan({
        ...posRevenueBasedLoan,
        amount: val,
      });
    }
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);
    setPosRevenueBasedLoan({
      ...posRevenueBasedLoan,
      amount: value,
    });
  };

  const handleDurationInputChange = (value: string) => {
    const val = handleInputChange("duration", value);
    if (val !== null) {
      setPosRevenueBasedLoan({
        ...posRevenueBasedLoan,
        duration: val,
        factorRate: constraints.posRevenueBasedLoan.factorRates?.[+value] || 0,
      });
    }
  };

  const handleDurationSliderChange = (value: number) => {
    setFieldValue("duration", value);
    setPosRevenueBasedLoan({
      ...posRevenueBasedLoan,
      duration: value,
      factorRate: constraints.posRevenueBasedLoan.factorRates?.[value] || 0,
    });
  };

  const handleTermsChange = (checked: boolean) => {
    setIsPartnerTermsAccepted(checked);
    if (checked) {
      setSubmissionError(false);
    }
  };

  useEffect(() => {
    setPosRevenueBasedLoan({
      amount: constraints.posRevenueBasedLoan?.amount?.initial,
      duration: sortedDurations[0],
      factorRate:
        constraints.posRevenueBasedLoan?.factorRates[sortedDurations[0]],
      durationType: constraints.posRevenueBasedLoan.duration
        .unit as LoanDurationType,
      posSalesStatements: [],
    });
  }, [sortedDurations]);

  useEffect(() => {
    setSortedDurations(
      Object.keys(constraints.posRevenueBasedLoan?.factorRates || {})
        .map(Number)
        .sort((a, b) => a - b)
    );
  }, [constraints.posRevenueBasedLoan]);

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
        <div className="cx-font-medium">One-time upfront fee</div>
        <div className="cx-text-3xl cx-font-bold">
          {constraints.posRevenueBasedLoan?.amount?.unit}{" "}
          {roundTo(factorFeeForPosRevenueBasedFinancing, 2).toLocaleString()}
        </div>
        <div className="cx-text-sm cx-text-text-secondary">
          Interest free and zero processing fee
        </div>
      </div>
      <Divider />
      <div className="cx-flex cx-flex-row cx-w-full cx-gap-6">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Loan amount</div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.posRevenueBasedLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          <div className="">
            <Slider
              initial={values.loanAmount}
              min={constraints.posRevenueBasedLoan?.amount?.min}
              max={constraints.posRevenueBasedLoan?.amount?.max}
              unit={constraints.posRevenueBasedLoan?.amount?.unit}
              value={values.loanAmount}
              label=""
              onChange={(value) => handleLoanAmountSliderChange(value)}
              renderThumb={false}
              interval={1000}
            />
          </div>
          {errors.loanAmount && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Loan duration</div>
          <div>
            <InputField
              inputProps={{
                name: "duration",
                value: values.duration,
                onChange: (e) => handleDurationInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend="Months"
            />
          </div>
          <div className="">
            <Slider
              initial={values.duration}
              min={sortedDurations[0]}
              max={sortedDurations[sortedDurations.length - 1]}
              unit={constraints.posRevenueBasedLoan?.duration?.unit}
              value={values.duration}
              label=""
              onChange={(value) => handleDurationSliderChange(value)}
              renderThumb={false}
              steps={sortedDurations}
            />
          </div>
          {errors.duration && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.duration}
            </div>
          )}
        </div>
      </div>
      <Divider />
      {/* DO NOT show terms and condition on calculator when opened on review screen */}
      {stage !== LoanApplicationStage.REVIEW && (
        <div className="cx-w-full">
          <TermsAndConditions
            onTermsChange={handleTermsChange}
            loanType={LoanProductType.POS_REVENUE_BASED_FINANCING}
          />
          {submissionError && (
            <div className="cx-w-full cx-bg-error-lighter cx-rounded-md cx-mt-4 cx-p-4">
              <div className="cx-text-sm cx-text-text-error">
                Please accept the Terms and Conditions to begin the application
              </div>
            </div>
          )}
        </div>
      )}
      <div className="cx-w-[400px]">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Continue"
            arrow="right"
            fullWidth
            onClick={handleSubmit}
            disabled={Object.keys(errors).length > 0}
          />
        )}
      </div>
    </div>
  );
};

export default PosRevenueBasedLoanCalculator;
