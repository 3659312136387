import { useState } from "react";
import { DisplayFormatMapper } from "../../types";
import { DropdownIcon } from "../../assets/icons";

export type Column<T> = {
  header: string;
  accessor: keyof T;
};

type TableProps<T, NestedT = any> = {
  data: T[];
  columns: Column<T>[];
  formatter?: DisplayFormatMapper<T>;
  nestedKeyName?: keyof T;
  nestedColumns?: Column<NestedT>[];
  nestedFormatter?: DisplayFormatMapper<NestedT>;
  nestedTableName?: string;
  isNestedTable?: boolean;
  separateRowsByBorder?: boolean;
  handleRowClick?: (rowData: T) => void;
};

const Table = <
  T extends Record<string, any>,
  NestedT extends Record<string, any> = any
>({
  data,
  columns,
  formatter = {},
  nestedKeyName,
  nestedColumns,
  nestedFormatter = {},
  nestedTableName,
  isNestedTable = false,
  separateRowsByBorder = true,
  handleRowClick,
}: TableProps<T, NestedT>) => {
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );

  const isBeforeLastRow = (rowNumber: number) => rowNumber < data.length - 1;

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // Recursive function to render rows and sub-rows
  const renderRows = (rows: T[], rowIndex = 0): JSX.Element[] => {
    // Base case: no more rows
    if (rowIndex >= rows.length) return [];

    const row = rows[rowIndex];
    const isExpanded = expandedRows[rowIndex];

    const renderCells = (
      columns: Column<T>[],
      columnIndex = 0
    ): JSX.Element[] => {
      // Base case: no more columns
      if (columnIndex >= columns.length) return [];

      const column = columns[columnIndex];
      const formatFn = formatter[column.accessor];
      const value = row[column.accessor];

      return [
        <td
          key={column.header}
          className={`cx-px-4 cx-py-5 ${
            separateRowsByBorder && isBeforeLastRow(rowIndex)
              ? "cx-border-b-[0.5px] cx-border-neutral-light"
              : ""
          } ${
            column.header === "Company" ? "cx-w-2/6" : ""
          } cx-text-sm cx-break-words cx-whitespace-normal ${
            column.header === "Debit" ? "" : "cx-text-neutral-darker"
          }`}
        >
          {formatFn ? formatFn(value) : value ?? "-"}
        </td>,
        // Recursively render remaining cells
        ...renderCells(columns, columnIndex + 1),
      ];
    };

    return [
      <tr
        key={rowIndex}
        onClick={() => handleRowClick?.(row)}
        className={`${
          handleRowClick ? "cx-cursor-pointer cx-hover:bg-neutral-lighter" : ""
        }`}
      >
        {nestedColumns && (
          <td
            className={`${
              separateRowsByBorder && isBeforeLastRow(rowIndex)
                ? "cx-border-b-[0.5px] cx-border-neutral-light"
                : ""
            } cx-text-sm cx-break-words cx-whitespace-normal`}
          >
            <div
              className={`cx-transition-transform cx-duration-200 cx-align-sub cx-inline-block cx-ml-2 ${
                isExpanded ? "cx-rotate-180" : "cx-rotate-0"
              }`}
              onClick={() => toggleRowExpansion(rowIndex)}
            >
              <DropdownIcon className="cx-text-brand-primary-regular cx-cursor-pointer cx-text-[#AAAAAA]" />
            </div>
          </td>
        )}
        {renderCells(columns)}
      </tr>,
      // Check if nestedData exists and render nested table (only if expanded)
      ...(nestedKeyName &&
      Array.isArray(row[nestedKeyName]) &&
      (row[nestedKeyName] as any[]).length > 0 &&
      isExpanded
        ? [
            <tr key={`nested-${rowIndex}`}>
              <td colSpan={columns.length + 1}>
                {" "}
                {/* Adjust colSpan to account for the extra arrow column */}
                <div className="cx-ml-6 cx-mt-2">
                  <Table<NestedT>
                    columns={nestedColumns ? nestedColumns : []}
                    data={row[nestedKeyName] as unknown as NestedT[]}
                    formatter={nestedFormatter}
                    nestedKeyName={nestedKeyName as keyof NestedT}
                    nestedTableName={nestedTableName}
                    isNestedTable={true}
                  />
                </div>
              </td>
            </tr>,
          ]
        : []),
      // Recursively render remaining rows
      ...renderRows(rows, rowIndex + 1),
    ];
  };

  return (
    <div
      className={`${
        isNestedTable && nestedTableName
          ? "cx-overflow-x-auto cx-border cx-border-neutral-light cx-bg-neutral-lighter cx-rounded-xl cx-py-4 cx-px-6"
          : ""
      }`}
    >
      {isNestedTable && nestedTableName && (
        <p className="cx-font-semibold cx-text-base cx-pb-5">
          {" "}
          {nestedTableName}{" "}
        </p>
      )}
      <div className="cx-overflow-x-auto cx-border cx-border-neutral-light cx-bg-white cx-rounded-xl cx-py-4 cx-px-6">
        <table className="cx-min-w-full">
          <thead>
            <tr>
              {/* Add an empty th for the arrow icon only if nested cols exist */}
              {nestedColumns && (
                <th className="cx-px-4 cx-py-4 cx-border-b cx-border-neutral-light cx-bg-white"></th>
              )}
              {columns.map((column) => (
                <th
                  key={column.header}
                  className="cx-px-4 cx-py-4 cx-border-b cx-border-neutral-light cx-bg-white cx-text-left cx-text-sm cx-font-normal cx-text-neutral-darker"
                >
                  {column.header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Render rows recursively */}
            {renderRows(data)}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
