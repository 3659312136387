import {
  LoanApplicationStage,
  LoanProductType,
} from "../../../hooks/api/types";
import { BusinessDocuments } from "./BusinessDocuments";
import { StepPrevButton, StepNextButton, Divider } from "../../../components";
import { ArrowLeftIcon } from "../../../assets/icons";
import { useStepper } from "../Stepper";
import { useLoanApplication } from "../../../contexts/loanApplication";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";
import { FinancialDocuments } from "./FinancialDocuments";
import { AdditionalDocuments } from "./AdditionalDocuments";

const isDocumentRequired = (isDisabled: boolean | undefined) =>
  typeof isDisabled !== "undefined" && !isDisabled;

const isDocumentUploaded = (
  documents: any[],
  isDisabled: boolean | undefined
) => (isDocumentRequired(isDisabled) ? documents.length > 0 : true);

const VAT_LIMIT = Number(import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000);

export const UploadDocuments = () => {
  const {
    state: {
      loanType,
      docTradeLicenses,
      docMOAs,
      docBankStatements,
      docVatStatements,
      invoices = [],
      revenueBasedLoan: { salesStatements = [] },
      buyNowPayLaterLoan: { salesInvoices = [] },
      posRevenueBasedLoan: { posSalesStatements = [] },
      annualRevenue,
    },
    actions: { update },
  } = useLoanApplication();
  const {
    enableVendorScreen,
    disableBankStatementsUpload,
    disableVATStatementsUpload,
    disableSalesStatementsUpload,
    disableInvoiceUpload,
    disableSalesInvoiceUpload,
  } = useLoanProductConfigFlags(loanType);
  const { previousStep, nextStep } = useStepper();
  const moveNext = async () => {
    await update({
      stage: enableVendorScreen
        ? LoanApplicationStage.VENDOR_INFORMATION
        : LoanApplicationStage.REVIEW,
    });
    nextStep();
  };

  const isRevenueBasedLoan =
    loanType === LoanProductType.REVENUE_BASED_FINANCING;
  const isBuyNowPayLaterLoan = loanType === LoanProductType.BNPL;
  const isInvoiceDiscountingLoan =
    loanType === LoanProductType.INVOICE_DISCOUNTING;
  const isPayableFinancingLoan = loanType === LoanProductType.PAYABLE_FINANCING;
  const isPosRevenueBasedLoan =
    loanType === LoanProductType.POS_REVENUE_BASED_FINANCING;

  const requiredDocumentsCriteria = {
    tradeLicenses: docTradeLicenses.length > 0,
    moas: docMOAs.length > 0,
    bankStatements: isDocumentUploaded(
      docBankStatements,
      disableBankStatementsUpload
    ),
    vatStatements:
      annualRevenue >= VAT_LIMIT
        ? isDocumentUploaded(docVatStatements, disableVATStatementsUpload)
        : true,
    salesStatements: isRevenueBasedLoan
      ? isDocumentUploaded(salesStatements, disableSalesStatementsUpload)
      : true,
    salesInvoices: isBuyNowPayLaterLoan
      ? isDocumentUploaded(salesInvoices, disableSalesInvoiceUpload)
      : true,
    invoices:
      isInvoiceDiscountingLoan || isPayableFinancingLoan
        ? isDocumentUploaded(invoices, disableInvoiceUpload)
        : true,
    posSalesStatements: isPosRevenueBasedLoan
      ? posSalesStatements.length > 0
      : true,
  };

  const isAllRequiredDocumentsUploaded = Object.values(
    requiredDocumentsCriteria
  ).every(Boolean);

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full cx-max-w-[884px]">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Upload following documents
        <p className="cx-text-text-secondary cx-font-light cx-text-sm cx-max-w-[584px]">
          Please upload following documents. All documents below are required
          for approval and allow us to offer you favorable loan offer.
        </p>
      </div>
      <div className="cx-w-full">
        <BusinessDocuments />
      </div>
      <div className="cx-w-full cx-my-8">
        <FinancialDocuments />
      </div>
      <div className="cx-w-full">
        <AdditionalDocuments />
      </div>
      <Divider className="cx-my-14 cx-max-w-[584px]" />
      <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4 cx-max-w-[584px]">
        <StepNextButton
          label="Submit documents"
          type="button"
          onClick={moveNext}
          disabled={!isAllRequiredDocumentsUploaded}
        />
      </div>
    </div>
  );
};
