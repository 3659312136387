import { getVendorTitle } from "../../LoanApplicationSteps/VendorInformation/utils";
import {
  LoanProductType,
  Vendor,
  VendorMediaPurpose,
} from "../../../hooks/api/types";
import { Accordion, Divider } from "../../../components";
import countries from "world-countries";
import { truncate } from "../../../utils";
import { DropdownIcon, FlagIcon } from "../../../assets/icons";
import {
  AccordionVariant,
  OpenIndicatorPosition,
} from "../../../components/Accordion/Accordion";
import VendorDocumentRequestRow from "./VendorDocumentRequestRow";
import React, { FC } from "react";
import { LoanApplication } from "../../../contexts";

export type Props = {
  loanApplication: LoanApplication;
  vendors?: Array<Vendor>;
  onSuccess?: () => void;
};

const PendingLoanVendorsSection: FC<Props> = ({
  loanApplication,
  vendors,
  onSuccess,
}) => {
  return (
    <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 cx-mb-6">
      <div className="cx-flex cx-flex-col cx-w-full cx-mb-6">
        <div className={"cx-flex cx-flex-row cx-place-content-between cx-mb-1"}>
          <div className="cx-text-2xl cx-font-bold">
            Pending{" "}
            {getVendorTitle(loanApplication?.loanType as LoanProductType)}s
          </div>
        </div>
        <div
          className={"cx-text-s cx-text-text-secondary cx-font-normal cx-mb-6"}
        >
          There are some missing documents that we require to proceed with your
          suppliers. Please add your documents.
        </div>
        <div
          className={
            "cx-flex cx-flex-col cx-gap-4 cx-flex-wrap cx-w-full cx-justify-between"
          }
        >
          {vendors?.map((vendor: Vendor) => (
            <div className={""} key={vendor.id}>
              <Accordion
                heading={
                  <div
                    className={
                      "cx-flex cx-flex-row cx-ml-3 cx-w-full cx-items-center cx-justify-between"
                    }
                  >
                    <div
                      className={
                        "cx-flex cx-flex-row cx-justify-center cx-items-center"
                      }
                    >
                      <div className={"cx-font-bold cx-text-2xl"}>
                        {
                          countries.find(
                            (country) => country.cca3 === vendor.country
                          )?.flag
                        }
                      </div>
                      <div
                        className={
                          "cx-flex cx-flex-row cx-w-full cx-ml-2 cx-justify-between"
                        }
                      >
                        <div className={"cx-font-medium cx-text-base"}>
                          {truncate(vendor.name, 40)}
                        </div>
                      </div>
                    </div>
                    <FlagIcon className={"cx-text-text-error"} />
                  </div>
                }
                openIndicatorPosition={OpenIndicatorPosition.LEFT}
                openIndicatorOverride={
                  <div
                    className={
                      "cx-border cx-rounded-2xl cx-h-5 cx-w-5 cx-flex cx-justify-center cx-items-center cx-content-center"
                    }
                  >
                    <DropdownIcon
                      className={
                        "-cx-rotate-90 cx-text-text-brand cx-w-3 cx-h-3"
                      }
                    />
                  </div>
                }
                accordionVariant={AccordionVariant.CHILD_OUTSIDE}
              >
                {Boolean(vendor?.loanVendorDocumentRequests?.length) && (
                  <div
                    className={
                      "cx-flex cx-flex-col cx-w-full cx-px-2 cx-text-s cx-font-normal cx-gap-5"
                    }
                  >
                    <div className={"cx-flex cx-flex-row"}>
                      <div className={"cx-w-5/12"}>Document Name</div>
                      <div className={"cx-w-5/12"}>Reason</div>
                      <div>Actions</div>
                    </div>
                    {vendor?.loanVendorDocumentRequests
                      ?.filter((request) => {
                        return (
                          request.type !== VendorMediaPurpose.BANK_STATEMENT
                        );
                      })
                      .map((request: any) => (
                        <div key={request.id}>
                          <Divider className={"cx-mb-4"} />
                          <VendorDocumentRequestRow
                            vendorDocumentRequest={request}
                            loanApplicationId={loanApplication.id}
                            onSuccess={onSuccess}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </Accordion>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PendingLoanVendorsSection;
