import { useContext, useState } from "react";
import { useFormik } from "formik";
import { Divider, InputField, StepNextButton } from "../../../components";
import { OTPType, StepperContext, useLoanApplication } from "../../../contexts";
import {
  PhoneNumberFormFields,
  PHONE_NUMBER_FORM_INITIAL_VALUES,
  PHONE_NUMBER_FORM_VALIDATION_SCHEMA,
} from "./service";
import { useSendOTP, useUser } from "../../../hooks";

const PhoneNumberForm = () => {
  const [updating, setUpdating] = useState(false);
  const { nextStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const { mutateAsync: sendOTP } = useSendOTP();
  const { data: user } = useUser();
  const localNumber = state.sourceId
    ? user?.localNumber ?? state.localNumber
    : state.localNumber;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldError,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = useFormik<PhoneNumberFormFields>({
    enableReinitialize: true,
    initialValues: {
      ...PHONE_NUMBER_FORM_INITIAL_VALUES,
      localNumber: localNumber,
    },
    validationSchema: PHONE_NUMBER_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      const localNumber = values.localNumber.replace(/\s/g, "");
      setUpdating(true);
      update(
        {
          countryCode: values.countryCode,
          localNumber,
        },
        {
          local: true,
        }
      )
        .then(() => {
          sendOTP({
            countryCode: values.countryCode,
            localNumber: values.localNumber,
          })
            .then(() => {
              update({ otpType: OTPType.SMS }, { local: true }).then(() => {
                nextStep(); // lands on SubStepName.OTP_FORM
              });
            })
            .catch((err) => {
              setFieldError("localNumber", err.message);
              setFieldTouched("localNumber", true, false);
            })
            .finally(() => {
              setUpdating(false);
            });
        })
        .catch((err) => {
          setFieldError("localNumber", err.message);
        });
    },
  });

  return (
    <div className="cx-flex cx-flex-col cx-items-center">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
        What is your mobile phone number?
      </div>
      <div className="cx-mt-2 cx-text-text-secondary cx-text-base">
        Only UAE mobile phone numbers are permitted at this time.
      </div>
      <div className="cx-max-w-[400px] cx-mt-10">
        <form onSubmit={handleSubmit}>
          <div className="cx-flex cx-flex-col">
            <div className="cx-flex cx-w-full">
              <div className="cx-max-w-[120px] cx-mr-1">
                <InputField
                  label="Country code"
                  inputProps={{
                    value: "🇦🇪 " + values.countryCode,
                    readOnly: true,
                  }}
                  error={
                    !!touched.localNumber && !!errors.localNumber
                      ? errors.localNumber
                      : ""
                  }
                  hideErrorMessage
                />
              </div>
              <div className="cx-ml-1">
                <InputField
                  label="Phone number"
                  inputProps={{
                    name: "localNumber",
                    value: values.localNumber,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                  error={
                    !!touched.localNumber && !!errors.localNumber
                      ? errors.localNumber
                      : ""
                  }
                  hideErrorMessage
                />
              </div>
            </div>
            {touched.localNumber && !!errors.localNumber && (
              <div className="cx-text-xs cx-py-1 cx-text-text-error">
                {errors.localNumber}
              </div>
            )}
            <div className="cx-mt-10">
              <Divider />
            </div>
            <div className="cx-w-full cx-mt-10">
              <StepNextButton
                label="Verify"
                loading={updating}
                disabled={updating}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PhoneNumberForm;
