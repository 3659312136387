import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InputField,
  Divider,
  StepPrevButton,
  StepNextButton,
  Modal,
  Accordion,
  Button,
} from "../../../components";
import {
  ArrowLeftIcon,
  BankIcon,
  DashboardLayoutIcon,
  InfoIcon,
  MonthlyStatementIcon,
  PosReceiptsIcon,
  HyphenIcon,
  PlusIcon,
} from "../../../assets/icons";
import { useStepper } from "../Stepper";
import { useSaveMastercardMerchantIds } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { LoanApplicationStage } from "../../../hooks/api/types";

const infoItems = [
  {
    icon: <DashboardLayoutIcon className="cx-mr-2" />,
    title: "Check Your Payment Processor Dashboard",
    content:
      "Log into your payment processor’s account (e.g., Stripe, PayPal, Square) and navigate to the “Account” or “Settings” section. Look for “Merchant ID” or “MID,” which is typically listed under your business details or transaction settings.",
  },
  {
    icon: <PosReceiptsIcon className="cx-mr-2" />,
    title: "Check POS Terminals or Receipts",
    content: "Check POS Terminals or Receipts content comes herer",
  },
  {
    icon: <BankIcon className="cx-mr-2" />,
    title: "Contact Your Payment Processor",
    content: "Contact Your Payment Processor content comes here",
  },
  {
    icon: <MonthlyStatementIcon className="cx-mr-2" />,
    title: "On Your Monthly Statement",
    content: "On Your Monthly Statement content comes here",
  },
];

const validationSchema = Yup.object({
  merchantIds: Yup.array()
    .of(
      Yup.string()
        .required("Merchant ID is required")
        .max(15, "Merchant ID must be at most 15 characters")
    )
    .min(1, "At least one Merchant ID is required")
    .max(5, "You can only add up to 5 Merchant IDs"),
});

type Props = {};

export const MerchantIDInputForm: React.FC<Props> = () => {
  const [openInfo, setOpenInfo] = useState(false);
  const { previousStep, nextStep } = useStepper();
  const { mutateAsync: saveMastercardMerchantIds, isLoading } =
    useSaveMastercardMerchantIds();
  const {
    state: { id, loanType },
    actions: { update },
  } = useLoanApplication();

  const moveNext = async () => {
    await update({
      stage: LoanApplicationStage.VENDOR_INFORMATION,
    });
    nextStep();
  };

  const {
    handleSubmit,
    values,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      merchantIds: [""], // Initial array of one empty merchant ID
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.merchantIds.length === 0) {
        moveNext();
        return;
      }
      saveMastercardMerchantIds({
        merchantIds: values.merchantIds.filter((id) => id !== ""), // Remove empty strings,
        loanApplicationId: id,
      })
        .then(() => {
          moveNext();
        })
        .catch((error) => {
          console.error("Error saving Merchant IDs:", error);
        });
    },
  });

  const addMerchantId = () => {
    if (values.merchantIds.length < 5) {
      setFieldValue("merchantIds", [...values.merchantIds, ""]);
    }
  };

  const removeMerchantId = (index: number) => {
    const updatedMerchantIds = values.merchantIds.filter((_, i) => i !== index);
    setFieldValue("merchantIds", updatedMerchantIds);
  };

  const updateMerchantId = (value: string, index: number) => {
    const updatedMerchantIds = [...values.merchantIds];
    updatedMerchantIds[index] = value;
    setFieldValue("merchantIds", updatedMerchantIds);
  };

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        {"Add your Merchant ID"}
        <p className="cx-text-text-secondary cx-font-light cx-text-sm">
          {
            "Please provide your Merchant ID to help us verify your financial information."
          }
        </p>
        <p className="cx-text-text-secondary cx-font-light cx-text-sm">
          {
            "Adding MID can give you access to faster loan approval and higher credit limits."
          }
        </p>
      </div>

      <div className="cx-max-w-[400px] cx-min-w-[368px]">
        <form onSubmit={handleSubmit}>
          {values.merchantIds.map((merchantId, index) => (
            <div key={index} className="cx-flex">
              <div className="cx-items-center cx-mt-4 cx-w-full">
                <InputField
                  label={`Merchant ID ${index + 1}`}
                  inputProps={{
                    value: merchantId,
                    onChange: (e) => updateMerchantId(e.target.value, index),
                    onBlur: () => {
                      if (
                        Array.isArray(touched.merchantIds) &&
                        !touched.merchantIds?.[index]
                      ) {
                        setFieldTouched(`merchantIds[${index}]`, true);
                      }
                    },
                  }}
                  tick={
                    Array.isArray(touched.merchantIds) &&
                    Array.isArray(errors.merchantIds) &&
                    touched.merchantIds?.[index] &&
                    !errors.merchantIds?.[index]
                  }
                  error={
                    Array.isArray(touched.merchantIds) &&
                    Array.isArray(errors.merchantIds) &&
                    touched.merchantIds?.[index] &&
                    errors.merchantIds?.[index]
                      ? errors.merchantIds[index]
                      : ""
                  }
                />
              </div>
              {values.merchantIds.length > 1 && (
                <button
                  type="button"
                  onClick={() => removeMerchantId(index)}
                  className="cx-ml-2 cx-text-red-500 "
                  disabled={values.merchantIds.length === 1} // Prevent removal if only one field remains
                >
                  <HyphenIcon />
                </button>
              )}
            </div>
          ))}
          {values.merchantIds.length < 5 && (
            <div className="cx-flex cx-my-2 cx-items-center">
              <PlusIcon
                className="cx-stroke-brand-primary-regular"
                height={"16"}
                width={"16"}
              />
              <span
                onClick={addMerchantId}
                className="cx-text-brand-primary-regular cx-m-1 cx-cursor-pointer"
              >
                Add another Merchant ID
              </span>
              <div className="cx-text-text-secondary cx-font-medium cx-text-sm cx-flex cx-items-center cx-justify-center">
                (you can add upto 5)
              </div>
            </div>
          )}
          <div className="cx-text-text-secondary cx-font-light cx-text-sm cx-flex cx-items-center cx-justify-center">
            <InfoIcon
              height={"16"}
              width={"16"}
              className="cx-stroke-brand-primary-regular cx-mr-2"
            />
            Learn how to find Merchant ID.
            <span
              className="cx-text-brand-primary-regular cx-ml-1 cx-cursor-pointer"
              onClick={() => {
                setOpenInfo(true);
              }}
            >
              Click here
            </span>
          </div>
          <div className="cx-my-10">
            <Divider />
          </div>

          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton
              label="Next"
              type="submit"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </form>
      </div>

      {openInfo && (
        <Modal
          isOpen={openInfo}
          onClose={() => {
            setOpenInfo(!openInfo);
          }}
          title="How to find Merchant ID"
          subtitle="Here are some ways from where you can find MID:"
        >
          <div className="cx-flex cx-flex-col cx-items-start cx-gap-6 cx-w-full">
            {infoItems.map((item) => (
              <Accordion
                key={item.title}
                heading={
                  <div className="cx-flex cx-items-center">
                    {item.icon}
                    <span className="cx-text-brand-primary-regular cx-w-full">
                      {item.title}
                    </span>
                  </div>
                }
              >
                {item.content}
              </Accordion>
            ))}
          </div>
          <div className="cx-w-full cx-mt-10">
            <Button
              type={"button"}
              fullWidth
              label={
                <div className="cx-flex">
                  <ArrowLeftIcon />
                  <span className="cx-ml-2">Go Back</span>
                </div>
              }
              onClick={() => {
                setOpenInfo(false);
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
