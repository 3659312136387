import { FC, useEffect, useState } from "react";
import { Tabs } from "../../../components";
import { Variant } from "../../../components/Tabs/Tabs";
import { getVendorTitle } from "../../LoanApplicationSteps/VendorInformation/utils";
import { LoanApplication, useLoanDashboard } from "../../../contexts";
import VendorManagementSection from "./VendorManagementSection";
import { LoanProductType } from "../../../hooks/api/types";

const LoanDetailsPage: FC = () => {
  const [currentLoan, setCurrentLoan] = useState<LoanApplication>();

  const {
    state: { loans, currentLoanApplicationId: loanId },
  } = useLoanDashboard();

  useEffect(() => {
    const loan = loans.find((loan) => loan.id === loanId);
    setCurrentLoan(loan);
  }, [loanId]);

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-content-center cx-background-radial-gradient cx-w-full cx-p-8">
      <div
        className={
          "cx-grow cx-flex cx-flex-col cx-content-center cx cx-items-center cx-w-full cx-max-w-[960px]"
        }
      >
        <Tabs
          startingTab={0}
          variant={Variant.light}
          tabs={[
            {
              label: `${
                currentLoan &&
                getVendorTitle(currentLoan?.loanType as LoanProductType)
              }s`,
              component: <VendorManagementSection />,
              onTabClicked: () => {},
            },
          ]}
        />
      </div>
    </div>
  );
};

export default LoanDetailsPage;
