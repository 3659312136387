import { useFormik } from "formik";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  Combobox,
  InputField,
  NumericInputField,
  StepNextButton,
  Divider,
  Button,
} from "../../../components";
import {
  IndustryType,
  LicensingAuthorityType,
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../../contexts";
import {
  ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER,
  TAX_PERCENTAGE_MULTIPLIER,
  LicensingAuthorityFormField,
  BusinessInformationFormFields,
  BUSINESS_INFORMATION_FORM_INITIAL_VALUES,
  getEffectiveBusinessInformationFormValidationSchema,
  DETAIL_FORM_YEAR_DROPDOWN_OPTIONS,
} from "./service";
import { LoanApplicationStage } from "../../../hooks/api/types";
import {
  useValidateLoanApplicationUniqueness,
  useGetLicensingAuthorities,
  useInitiateCopy,
  useSendOTPByLoanApplicationId,
  useMedia,
  useGetIndustryTypes,
  useGetBusinessTypes,
} from "../../../hooks";
import { useDpConfig } from "../../../contexts/dpConfig";
import { ExistingProfileIcon } from "../../../assets/icons";
import { useStepper } from "../Stepper";
import { updateLoansStateForLegacyFlow } from "../../LoanCalculator/service";
import { useLoanProductConfigFlags } from "../../../hooks/useLoanProductConfigFlags";
import { OfficeAddressForm } from "./OfficeAddressForm";

const BusinessInformationForm = () => {
  const {
    state,
    actions: { update, refetch },
  } = useLoanApplication();
  const { state: calculatorState } = useLoanCalculator();
  const { nextStep } = useContext(StepperContext);
  const { mutateAsync: getLicensingAuthorities } = useGetLicensingAuthorities();

  const { mutateAsync: validateLoanApplicationUniqueness } =
    useValidateLoanApplicationUniqueness();
  const { mutateAsync: initiateCopy } = useInitiateCopy();
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const { disableRevenueHistory } = useLoanProductConfigFlags(state.loanType);
  const { gotoStep } = useStepper();

  const [licensingAuthorities, setLicensingAuthorities] = useState<
    Array<LicensingAuthorityFormField>
  >([]);
  const [profileAlreadyExists, setProfileAlreadyExists] =
    useState<boolean>(false);
  const [isSamePhoneNumber, setIsSamePhoneNumber] = useState(false); // true if the phone entered earlier is same as duplicate loan application found with same TL and licensing authority
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState("");
  const [
    loanApplicationIdFromExistingProfile,
    setLoanApplicationIdFromExistingProfile,
  ] = useState("");
  const { mutateAsync: sendOTPByLoanApplicationId } =
    useSendOTPByLoanApplicationId();
  const { upload } = useMedia();
  const { mutateAsync: getIndustryTypes } = useGetIndustryTypes();
  const { mutateAsync: getBusinessTypes } = useGetBusinessTypes();
  const [industryTypes, setIndustryTypes] = useState<
    { value: string; label: string }[]
  >([]);
  const [businessTypes, setBusinessTypes] = useState<
    { value: string; label: string }[]
  >([]);

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik<BusinessInformationFormFields>({
    initialValues: {
      ...BUSINESS_INFORMATION_FORM_INITIAL_VALUES,
      tradeLicenseNumber: state.tradeLicenseNumber,
      annualRevenue: state.annualRevenue,
      taxRegistrationNumber: state.taxRegistrationNumber,
      lastYearPaidTax: state.lastYearPaidTax,
      licensingAuthorityId: state.licensingAuthorityId,
      ...(disableRevenueHistory
        ? { annualRevenue: 0, taxRegistrationNumber: "", lastYearPaidTax: 0 }
        : {}),
      companyName: state.businessName,
      companyYearEstabilished: state.businessYearEstabilished,
      businessType: state.businessType,
      industryTypeId: state.industryTypeId,
      emirate: state.businessEmirate,
      area: state.businessArea,
      street: state.businessStreet,
      building: state.businessBuilding,
      floor: state.businessFloor,
      number: state.businessUnit,
    },
    validationSchema: getEffectiveBusinessInformationFormValidationSchema(
      disableRevenueHistory!
    ),
    enableReinitialize: true,
    onSubmit: async (values) => {
      const {
        isDuplicateApplication,
        isPhoneNumberMatching,
        sourceId: loanApplicationIdFromExistingProfile,
        phoneNumber,
      } = await validateLoanApplicationUniqueness({
        tradeLicenseNumber: values.tradeLicenseNumber,
        licensingAuthority: values.licensingAuthorityId,
        loanId: state.id,
      });

      if (isDuplicateApplication) {
        setProfileAlreadyExists(isDuplicateApplication);
        setIsSamePhoneNumber(isPhoneNumberMatching);
        setLoanApplicationIdFromExistingProfile(
          loanApplicationIdFromExistingProfile
        );
        setMaskedPhoneNumber(phoneNumber);
      } else {
        await update({
          tradeLicenseNumber: values.tradeLicenseNumber,
          annualRevenue: values.annualRevenue,
          taxRegistrationNumber: values.taxRegistrationNumber,
          lastYearPaidTax: values.lastYearPaidTax,
          licensingAuthorityId: values.licensingAuthorityId,
          stage: LoanApplicationStage.COMPANY_INFORMATION,
          businessName: values.companyName,
          businessYearEstabilished: values.companyYearEstabilished,
          businessType: values.businessType,
          businessEmirate: values.emirate,
          businessArea: values.area,
          businessStreet: values.street,
          businessBuilding: values.building,
          businessFloor: values.floor,
          businessUnit: values.number,
          industryTypeId: values.industryTypeId,
        });
        await refetch();
        nextStep(); // lands on SubStepName.LOAN_CALCULATOR
      }
    },
  });

  const shouldApplyTax = useMemo(() => {
    return values.annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
  }, [values.annualRevenue]);

  useEffect(() => {
    setFieldValue(
      "lastYearPaidTax",
      shouldApplyTax ? values.annualRevenue * TAX_PERCENTAGE_MULTIPLIER : 0
    );
  }, [shouldApplyTax, values.annualRevenue]);

  useEffect(() => {
    const initialize = async () => {
      const res = await getLicensingAuthorities();
      const _authorities = res.map((e: LicensingAuthorityType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setLicensingAuthorities(_authorities);
    };
    initialize().catch((err) => {
      console.log(err);
    });
    const initIndustryTypes = async () => {
      const _types = await getIndustryTypes();
      const _industryTypes = _types.map((e: IndustryType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setIndustryTypes(_industryTypes);
    };
    const initBusinessTypes = async () => {
      const _types = await getBusinessTypes();
      const businessTypes = _types.map((e: IndustryType) => ({
        value: e.id,
        label: e.name,
        ...e,
      }));
      setBusinessTypes(businessTypes);
    };
    initIndustryTypes();
    initBusinessTypes();
  }, []);

  const copyFromExistingProfile = async () => {
    if (loanApplicationIdFromExistingProfile) {
      await initiateCopy({
        loanApplicationId: state.id,
        refId: loanApplicationIdFromExistingProfile,
      });
      if (!disableLOCFlow) {
        const { loanType } = await update({
          id: state.id,
          sourceId: loanApplicationIdFromExistingProfile,
          stage: LoanApplicationStage.CALCULATOR,
        });
        // this is needed because FE moves to calculator before refetching is done
        // and loanType is still undefined which is needed to load correct config on calculator screen
        if (loanType) {
          await update(
            {
              loanType: loanType,
            },
            {
              local: true,
            }
          );
        }
      } else {
        // make updates for legacy flow
        await updateLoansStateForLegacyFlow(
          state.id,
          calculatorState,
          update,
          upload,
          state.invoiceFiles,
          state.salesStatementFiles,
          state.salesInvoiceFiles
        );
      }
      await refetch();
    }
  };

  const goToOtpForm = async () => {
    await sendOTPByLoanApplicationId({
      loanApplicationId: loanApplicationIdFromExistingProfile,
    });
    await update(
      {
        sourceId: loanApplicationIdFromExistingProfile,
        localNumber: maskedPhoneNumber,
      },
      {
        local: true,
      }
    ); // update sourceId from which we copy if otp is successfull

    if (!disableLOCFlow) {
      // if we are in LOC flow
      gotoStep([0, 3]); // Otp form step
    } else {
      gotoStep([1, 2]); // Otp form step
    }
  };

  if (profileAlreadyExists) {
    return (
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-max-w-[600px] cx-w-full">
        <ExistingProfileIcon />
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
          Existing Profile Available!
        </div>
        <div className="cx-text-text-secondary cx-font-normal cx-text-base cx-text-center cx-mb-10">
          We've found an existing profile for you with the current trade license
          and economic zone. You can update the trade license number to proceed,
          or simply continue with your existing profile to submit your request.
        </div>
        <div className="cx-flex cx-flex-row cx-gap-4 cx-w-full">
          <Button
            label="Add new trade license number"
            outlined
            fullWidth
            onClick={() => {
              setProfileAlreadyExists(false);
              setIsSamePhoneNumber(false);
              setLoanApplicationIdFromExistingProfile("");
            }}
          />
          <Button
            label="Continue with existing profile"
            fullWidth
            onClick={async () => {
              if (isSamePhoneNumber) {
                // continue by copying data from previous application
                await copyFromExistingProfile();
              } else {
                // previous number entered does not match with profile found so ask for OTP again
                await goToOtpForm();
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Please tell us about your business
      </div>
      <div className="cx-max-w-[500px] cx-w-full">
        <form onSubmit={handleSubmit}>
          <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4">
            Business details
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <div className="cx-w-full cx-mr-1">
              <InputField
                label="Company name"
                tick={touched.companyName && !errors.companyName}
                inputProps={{
                  name: "companyName",
                  value: values.companyName,
                  onChange: handleChange,
                  onBlur: handleBlur,
                }}
                error={touched.companyName ? errors.companyName : ""}
              />
            </div>
            <div className="cx-w-full cx-ml-1">
              <Combobox
                label="Year company estabilished"
                options={DETAIL_FORM_YEAR_DROPDOWN_OPTIONS}
                inputProps={{
                  name: "companyYearEstabilished",
                  value: DETAIL_FORM_YEAR_DROPDOWN_OPTIONS.find(
                    (e) => e.value === values.companyYearEstabilished
                  ),
                  onBlur: () => setFieldTouched("companyYearEstabilished"),
                }}
                onChange={handleChange("companyYearEstabilished")}
                error={
                  touched.companyYearEstabilished
                    ? errors.companyYearEstabilished
                    : ""
                }
              />
            </div>
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4">
            <div className="cx-w-full cx-mr-1">
              <Combobox
                label="Business type"
                options={businessTypes}
                inputProps={{
                  name: "businessType",
                  value: businessTypes.find(
                    (e) => e.value === values.businessType
                  ),
                  onBlur: () => setFieldTouched("businessType"),
                }}
                onChange={handleChange("businessType")}
                error={touched.businessType ? errors.businessType : ""}
              />
            </div>
            <div className="cx-w-full cx-ml-1">
              <Combobox
                label="Industry type"
                options={industryTypes}
                inputProps={{
                  name: "industryTypeId",
                  value: industryTypes.find(
                    (e) => e.value === values.industryTypeId
                  ),
                  onBlur: () => setFieldTouched("industryTypeId"),
                }}
                onChange={handleChange("industryTypeId")}
                error={
                  touched.industryTypeId && !!errors.industryTypeId
                    ? errors.industryTypeId
                    : ""
                }
              />
            </div>
          </div>
          <div className="cx-mb-4">
            <InputField
              label="Trade license number"
              tick={touched.tradeLicenseNumber && !errors.tradeLicenseNumber}
              error={
                touched.tradeLicenseNumber && !!errors.tradeLicenseNumber
                  ? errors.tradeLicenseNumber
                  : ""
              }
              inputProps={{
                name: "tradeLicenseNumber",
                value: values.tradeLicenseNumber,
                onChange: handleChange,
                onBlur: handleBlur,
              }}
              hint="Please add your trade license number exactly as it appears on your license, including any special characters."
            />
          </div>
          <div className="cx-flex cx-justify-between cx-mb-4 cx-gap-2">
            <div className="cx-w-full cx-flex-1">
              <Combobox
                label="Licensing Authority"
                placeholder="Select licensing authority"
                options={licensingAuthorities}
                inputProps={{
                  name: "licensingAuthorityId",
                  value: licensingAuthorities.find(
                    (e) => e.value === values.licensingAuthorityId
                  ),
                  onBlur: () => setFieldTouched("licensingAuthorityId"),
                }}
                onChange={handleChange("licensingAuthorityId")}
                error={
                  touched.licensingAuthorityId && !!errors.licensingAuthorityId
                    ? errors.licensingAuthorityId
                    : ""
                }
              />
            </div>
            {!disableRevenueHistory && (
              <div className="cx-w-full cx-flex-1">
                <NumericInputField
                  label="Previous year annual revenue"
                  isValid={touched.annualRevenue && !errors.annualRevenue}
                  value={values.annualRevenue}
                  error={
                    touched.annualRevenue && !!errors.annualRevenue
                      ? errors.annualRevenue
                      : ""
                  }
                  setValue={(value) => {
                    setFieldValue("annualRevenue", value);
                  }}
                />
              </div>
            )}
          </div>

          {shouldApplyTax && (
            <div className="cx-flex cx-justify-between cx-mb-4">
              <div className="cx-w-full cx-mr-1">
                <InputField
                  label="Tax registration number"
                  tick={
                    touched.taxRegistrationNumber &&
                    !errors.taxRegistrationNumber
                  }
                  error={
                    touched.taxRegistrationNumber &&
                    !!errors.taxRegistrationNumber
                      ? errors.taxRegistrationNumber
                      : ""
                  }
                  inputProps={{
                    name: "taxRegistrationNumber",
                    value: values.taxRegistrationNumber,
                    onChange: handleChange,
                    onBlur: handleBlur,
                  }}
                />
              </div>
              <div className="cx-w-full cx-ml-1">
                <NumericInputField
                  label="VAT Paid in the Previous Year"
                  isValid={touched.lastYearPaidTax && !errors.lastYearPaidTax}
                  value={values.lastYearPaidTax}
                  error={
                    touched.lastYearPaidTax && !!errors.lastYearPaidTax
                      ? errors.lastYearPaidTax
                      : ""
                  }
                  setValue={(value) => {
                    setFieldValue("lastYearPaidTax", value);
                  }}
                  onBlur={() => setFieldTouched("lastYearPaidTax")}
                />
              </div>
            </div>
          )}
          <div className="cx-mt-10">
            <OfficeAddressForm
              values={values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </div>
          <div className="cx-my-10">
            <Divider />
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton loading={state.updating} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessInformationForm;
