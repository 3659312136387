import { useContext, useState } from "react";
import { StepperContext, useLoanApplication } from "../../../contexts";
import ApplicantInformationForm from "./ApplicantInformationForm";
import { LoanApplicationStage } from "../../../hooks/api/types";
import { ButtonLoaderIcon } from "../../../assets/icons";
import ShareholderCard from "./ShareholderCard";
import { Divider, StepNextButton } from "../../../components";

const Loader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

const OwnersInformationForm = () => {
  const { nextStep: onNextStep } = useContext(StepperContext);
  const {
    state,
    actions: { update },
  } = useLoanApplication();

  const [addPrimaryApplicant, setAddPrimaryApplicant] =
    useState<boolean>(false);

  async function handleNextStep() {
    onNextStep();
    await update({
      stage: LoanApplicationStage.SHAREHOLDER_INFORMATION,
    });
  }

  if (state.loading) {
    return <Loader />;
  }

  const showEmptyState =
    state.individualShareholders.length === 0 &&
    state.companyShareholders.length === 0 &&
    !addPrimaryApplicant;

  if (showEmptyState) {
    return (
      <div className="cx-w-full cx-flex cx-flex-col cx-items-center cx-max-w-[960px]">
        <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center">
          Owners information{" "}
        </div>
        <div className="cx-font-medium cx-text-text-primary cx-text-base cx-text-center cx-my-2">
          We’d love to learn more about you! Help us serve you better by filling
          out your details.
        </div>
        <div className="cx-flex cx-flex-row cx-gap-x-4 cx-justify-center cx-w-full cx-mt-8">
          <ShareholderCard
            mode="add"
            name="Fill in your details"
            onClick={() => setAddPrimaryApplicant(true)}
            isPrimaryApplicant={true}
          />
        </div>
        <Divider className="cx-max-w-[400px] cx-my-10" />
        <div className="cx-w-full cx-flex cx-py-6 cx-p-4 cx-max-w-[400px]">
          <div className="cx-w-full">
            <StepNextButton label="Next" disabled={true} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <ApplicantInformationForm
      onSubmit={handleNextStep}
      shareholderExists={false}
    />
  );
};

export default OwnersInformationForm;
