import { LoanProductType, Vendor } from "../../../hooks/api/types";
import { FC, useState } from "react";
import { getVendorTitle } from "../../LoanApplicationSteps/VendorInformation/utils";
import VendorCard from "../../LoanApplicationSteps/VendorInformation/VendorCard";
import VendorInformationModal, {
  AddVendorModalVariant,
} from "../../LoanApplicationSteps/VendorInformation/VendorInformationModal";
import { useDeleteVendor } from "../../../hooks";
import { LoanApplication } from "../../../contexts";
import { PlusIcon } from "../../../assets/icons";
import { Button } from "../../../components";

export type Props = {
  loanApplication: LoanApplication;
  vendors?: Array<Vendor>;
  onSuccess?: () => void;
};

const NewLoanVendorsSection: FC<Props> = ({
  loanApplication,
  vendors,
  onSuccess,
}) => {
  const [selectedVendor, setSelectedVendor] = useState<
    Partial<Vendor> | undefined
  >(undefined);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState<boolean>(false);

  const { mutateAsync: deleteVendor } = useDeleteVendor();

  return (
    <div className={"cx-mb-10"}>
      <div className="cx-bg-white cx-rounded-lg cx-w-full cx-shadow-md cx-p-10 ">
        <div className="cx-flex cx-flex-col cx-w-full cx-mb-6">
          <div
            className={"cx-flex cx-flex-row cx-place-content-between cx-mb-1"}
          >
            <div className="cx-text-2xl cx-font-bold">
              New {getVendorTitle(loanApplication?.loanType as LoanProductType)}
              s
            </div>
            <Button
              label={
                <div className="cx-flex cx-items-center">
                  <PlusIcon className="cx-mr-2" />
                  <div>
                    Add{" "}
                    {getVendorTitle(
                      loanApplication?.loanType as LoanProductType
                    )}
                  </div>
                </div>
              }
              slim={true}
              onClick={() => {
                setSelectedVendor(undefined);
                setIsVendorModalOpen(true);
              }}
            />
          </div>
          <div
            className={
              "cx-text-s cx-text-text-secondary cx-font-normal cx-mb-6"
            }
          >
            if you want you can still add new suppliers from here.
          </div>
          <div
            className={
              "cx-flex cx-flex-row cx-flex-wrap cx-w-full cx-justify-between"
            }
          >
            {vendors?.map((vendor: any) => (
              <VendorCard
                key={vendor.loanVendorId}
                vendor={vendor}
                onDelete={async () => {
                  //all aspects matching the vendor should be removed
                  await deleteVendor({
                    loanApplicationId: loanApplication.id,
                    loanVendorId: vendor.loanVendorId,
                  });
                  onSuccess?.();
                }}
                onEdit={() => {
                  console.log("selected vendor", vendor);
                  setSelectedVendor(vendor);
                  setIsVendorModalOpen(true);
                }}
                className={"cx-w-[428px]"}
              />
            ))}
          </div>
        </div>
      </div>
      <VendorInformationModal
        isOpen={isVendorModalOpen}
        loanState={loanApplication}
        onClose={() => {
          setIsVendorModalOpen(false);
        }}
        onAddVendor={async () => {
          setIsVendorModalOpen(false);
          onSuccess?.();
        }}
        vendorState={selectedVendor as Vendor}
        variant={AddVendorModalVariant.ALL_MANDATORY}
      />
    </div>
  );
};

export default NewLoanVendorsSection;
