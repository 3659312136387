import {
  ConnectIcon,
  UploadIcon,
  GrayedOutTickIcon,
  GreenTickIcon,
} from "../../../assets/icons";

const UploadBtn = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="cx-text-text-brand cx-flex cx-flex-row cx-items-center cx-gap-2 cx-py-2 cx-px-4 cx-rounded-full cx-border-1.5 cx-shadow-sm"
      onClick={onClick}
    >
      <UploadIcon className="cx-h-4 cx-w-4" />
      <div className="cx-font-medium cx-text-sm">Upload</div>
    </button>
  );
};

const ConnectBtn = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="cx-text-text-brand cx-flex cx-flex-row cx-items-center cx-gap-2 cx-py-2 cx-px-4 cx-rounded-full cx-border-1.5 cx-shadow-sm"
      onClick={onClick}
    >
      <ConnectIcon className="cx-h-4 cx-w-4" />
      <div className="cx-font-medium cx-text-sm">Connect</div>
    </button>
  );
};

export const UploadDocumentItem = ({
  title,
  description,
  isOptional = false,
  isConnected = false,
  uploadedDocsCount = 0,
  onClick,
  showUploadBtn = true,
  leantechConection = false,
}: {
  title: string;
  description: string;
  isOptional?: boolean;
  isConnected?: boolean;
  uploadedDocsCount?: number;
  onClick?: () => void;
  showUploadBtn?: boolean;
  leantechConection?: boolean;
}) => {
  const getDescription = (uploadedDocsCount: number, description: string) => {
    if (uploadedDocsCount === 0) return description;
    return `${uploadedDocsCount} document${
      uploadedDocsCount > 1 ? "s" : ""
    } added successfully`;
  };

  return (
    <div className="cx-flex cx-flex-row cx-p-4 cx-bg-white cx-rounded-xl cx-shadow-sm cx-border cx-w-full cx-justify-between">
      <div className="cx-flex cx-items-center">
        {uploadedDocsCount > 0 || leantechConection ? (
          <GreenTickIcon />
        ) : (
          <GrayedOutTickIcon />
        )}
      </div>
      <div className="cx-mx-4 cx-w-full">
        <div className="cx-font-medium cx-text-sm cx-text-text-primary cx-mb-1">
          {title}{" "}
          {isOptional && (
            <span className="cx-font-medium cx-text-xs cx-text-stroke-tertiary cx-px-2 cx-border cx-rounded-lg cx-bg-gray-200">
              OPTIONAL
            </span>
          )}
        </div>
        <div className="cx-font-normal cx-text-xs cx-text-text-secondary">
          {getDescription(uploadedDocsCount, description)}
        </div>
      </div>
      {isConnected && <ConnectBtn onClick={onClick} />}
      {showUploadBtn && !isConnected && <UploadBtn onClick={onClick} />}
    </div>
  );
};
