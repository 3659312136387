import { FC } from "react";
import { Button, Divider, InputField, Slider } from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { useFormik } from "formik";
import {
  getLocPayableFinancingValidationSchema,
  LINE_OF_CREDIT_INITIAL_VALUES,
} from "./service";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ArrowLeftIcon } from "../../assets/icons";
import { LoanApplicationStage, LoanProductType } from "../../hooks/api/types";

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const LocPayableFinancingCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const {
    state: { locPayableFinancingLoan, constraints },
    actions: { setLocPayableFinancingLoan },
  } = useLoanCalculator();

  const {
    actions: { update },
  } = useLoanApplication();

  const { nextStep } = useStepper();

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...LINE_OF_CREDIT_INITIAL_VALUES,
      loanAmount:
        constraints.locPayableFinancingLoan.amount.initial ||
        LINE_OF_CREDIT_INITIAL_VALUES.loanAmount,
    },
    validationSchema: getLocPayableFinancingValidationSchema({
      loanAmount: {
        min: constraints.locPayableFinancingLoan.amount.min,
        max: constraints.locPayableFinancingLoan.amount.max,
        unit: constraints.locPayableFinancingLoan?.amount?.unit ?? "AED",
      },
    }),
    onSubmit: async (values) => {
      await update({
        loanType: LoanProductType.LOC_PAYABLE_FINANCING,
        stage: LoanApplicationStage.OWNER_INFORMATION,
        locPayableFinancingLoan: {
          amount: values.loanAmount,
        },
      });
      setLocPayableFinancingLoan({
        ...locPayableFinancingLoan,
        amount: values.loanAmount,
      });
      nextStep();
    },
  });

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }
    setFieldValue(name, num, true);
  };

  const handleLoanAmountInputChange = (value: string) => {
    handleInputChange("loanAmount", value);
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);
  };

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-text-center cx-w-full">
        <div className="cx-font-bold cx-text-2xl">Line of credit</div>
        <div className="cx-text-sm cx-text-text-secondary cx-mt-1 cx-max-w-[396px]">
          A line of credit provides businesses with ongoing access to funds up
          to an approved limit, allowing for flexible borrowing.
        </div>
      </div>
      <Divider />

      <div className="cx-flex cx-flex-row cx-w-full">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Line of credit</div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.locPayableFinancingLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          <div className="">
            <Slider
              initial={values.loanAmount}
              min={constraints.locPayableFinancingLoan?.amount?.min}
              max={constraints.locPayableFinancingLoan?.amount?.max}
              unit={constraints.locPayableFinancingLoan?.amount?.unit}
              value={values.loanAmount}
              label=""
              onChange={(value) => handleLoanAmountSliderChange(value)}
              renderThumb={false}
              interval={1000}
            />
          </div>
          {errors.loanAmount && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
      </div>
      <div className="cx-w-full">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Continue"
            arrow="right"
            fullWidth
            onClick={handleSubmit}
            disabled={Object.keys(errors).length > 0}
          />
        )}
      </div>
    </div>
  );
};

export default LocPayableFinancingCalculator;
