import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { LoanApplicationStage, LoanProductType } from "../../hooks/api/types";
import { StepName, SubStepName, legacyStepsConfig, locStepsConfig } from ".";
import {
  StepperContext,
  StepperContextProvider,
  StepProps,
  useLoanApplication,
} from "../../contexts";
import { useDpConfig } from "../../contexts/dpConfig";
import { DistributionPartnerSettings } from "../../contexts/dpConfig/types";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";
import { cloneDeep } from "lodash";

//todo: this code block refers to incomplete logic to handle scenario where if loan application flow is switched while application is in progress
// export function getShouldSwitchToLOCFlow(loanApplicationState: any, settings: DistributionPartnerSettings): boolean {
//   const locFlowStepsBeforeLoanCalculator = [LoanApplicationStage.BUSINESS_INFORMATION, LoanApplicationStage.COMPANY_INFORMATION, LoanApplicationStage.DOCUMENTS_UPLOAD, LoanApplicationStage.ONBOARDING, LoanApplicationStage.BRANDING]
//   const legacyFlowStepsBeforeLoanCreation = [LoanApplicationStage.CALCULATOR]
//   return false
//   return (loanApplicationState?.id &&
//     (
//       locFlowStepsBeforeLoanCalculator.includes(loanApplicationState?.stage)
//       // ||
//       // legacyFlowStepsBeforeLoanCreation.includes(loanApplicationState?.stage)
//     ) &&
//     settings.disableLOCFlow)
// }

export const STAGE_STEP_NAME_MAPPING_LEGACY_FLOW: {
  [k in LoanApplicationStage]: [string, number];
} = {
  // [LoanApplicationStage.REASON]: [StepName.LOAN_INITIAL, 0],
  [LoanApplicationStage.REASON]: [StepName.LOAN_REASONS, 0],
  [LoanApplicationStage.TYPE]: [StepName.LOAN_TYPE, 0],
  [LoanApplicationStage.BRANDING]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.CALCULATOR]: [StepName.LOAN_CALCULATOR, 0],
  [LoanApplicationStage.BUSINESS_INFORMATION]: [
    StepName.BUSINESS_INFORMATION,
    0,
  ],
  [LoanApplicationStage.COMPANY_INFORMATION]: [
    StepName.BUSINESS_INFORMATION,
    0,
  ],
  [LoanApplicationStage.DOCUMENTS_UPLOAD]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.ONBOARDING]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.OWNER_INFORMATION]: [StepName.OWNERS_INFORMATION, 0],
  [LoanApplicationStage.SHAREHOLDER_INFORMATION]: [
    StepName.OWNERS_INFORMATION,
    1,
  ],
  [LoanApplicationStage.FINANCIAL_INFORMATION]: [
    StepName.FINANCIAL_INFORMATION,
    0,
  ],
  [LoanApplicationStage.VAT_STATEMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.GENERIC_DOCUMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.MORE_INFORMATION]: [StepName.FINANCIAL_INFORMATION, 2],
  [LoanApplicationStage.MANUAL_ACCOUNTS]: [StepName.FINANCIAL_INFORMATION, 1],
  [LoanApplicationStage.REVIEW]: [StepName.APPLICATION_REVIEW, 0],
  [LoanApplicationStage.BANK_STATEMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.KYB]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.KYC]: [StepName.OWNERS_INFORMATION, 2],
  [LoanApplicationStage.VENDOR_INFORMATION]: [StepName.VENDOR_INFORMATION, 0],
  [LoanApplicationStage.SUCCESS]: [StepName.SUCCESS, 0],
};

export const STAGE_STEP_NAME_MAPPING_LOC_FLOW: {
  [k in LoanApplicationStage]: [string, number];
} = {
  // [LoanApplicationStage.REASON]: [StepName.LOAN_INITIAL, 0],
  [LoanApplicationStage.REASON]: [StepName.LOAN_REASONS, 0],
  [LoanApplicationStage.TYPE]: [StepName.LOAN_TYPE, 0],
  [LoanApplicationStage.BRANDING]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.CALCULATOR]: [StepName.LOAN_CALCULATOR, 0],
  [LoanApplicationStage.BUSINESS_INFORMATION]: [
    StepName.BUSINESS_INFORMATION,
    0,
  ],
  [LoanApplicationStage.COMPANY_INFORMATION]: [
    StepName.BUSINESS_INFORMATION,
    0,
  ],
  [LoanApplicationStage.DOCUMENTS_UPLOAD]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.ONBOARDING]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.OWNER_INFORMATION]: [StepName.OWNERS_INFORMATION, 0],
  [LoanApplicationStage.SHAREHOLDER_INFORMATION]: [
    StepName.OWNERS_INFORMATION,
    1,
  ],
  [LoanApplicationStage.FINANCIAL_INFORMATION]: [
    StepName.FINANCIAL_INFORMATION,
    0,
  ],
  [LoanApplicationStage.VAT_STATEMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.GENERIC_DOCUMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.MORE_INFORMATION]: [StepName.FINANCIAL_INFORMATION, 2],
  [LoanApplicationStage.MANUAL_ACCOUNTS]: [StepName.FINANCIAL_INFORMATION, 1],
  [LoanApplicationStage.VENDOR_INFORMATION]: [StepName.VENDOR_INFORMATION, 0],
  [LoanApplicationStage.REVIEW]: [StepName.APPLICATION_REVIEW, 0],
  [LoanApplicationStage.BANK_STATEMENTS]: [StepName.UPLOAD_DOCUMENTS, 0],
  [LoanApplicationStage.KYB]: [StepName.BUSINESS_INFORMATION, 0],
  [LoanApplicationStage.KYC]: [StepName.OWNERS_INFORMATION, 2],
  [LoanApplicationStage.SUCCESS]: [StepName.SUCCESS, 0],
};

export const getStepperConfig = (
  settings: DistributionPartnerSettings
): StepProps[] => {
  if (settings.disableLOCFlow) {
    return cloneDeep(legacyStepsConfig);
  }
  return cloneDeep(locStepsConfig);
};

export const getStepperStage = (
  loanApplicationState: any,
  settings: DistributionPartnerSettings
) => {
  const loanApplicationStage =
    loanApplicationState.stage as LoanApplicationStage;
  if (settings.disableLOCFlow) {
    return STAGE_STEP_NAME_MAPPING_LEGACY_FLOW[loanApplicationStage];
  }
  return STAGE_STEP_NAME_MAPPING_LOC_FLOW[loanApplicationStage];
};

const createConditionalStepConfig = (
  settings: DistributionPartnerSettings,
  shouldHideFinancialInformation?: boolean,
  disableBankStatementsUpload?: boolean,
  disableSalesStatementsUpload?: boolean,
  disableVATStatementsUpload?: boolean,
  disbursementAccountProvided?: boolean,
  showMerchantIdStep?: boolean,
  enableVendorScreen?: boolean
) => {
  // const legacyFlowStepsBeforeLoanCreation = [ LoanApplicationStage.CALCULATOR ]
  // const shouldSwitchToLOCFlow = loanApplicationState?.state.id && legacyFlowStepsBeforeLoanCreation.includes(loanApplicationState?.state.stage) && settings.disableLOCFlow

  /*
   * if disableLOCFlow is true switch to legacyStepsConfig, but if disableLOCFlow is true and getShouldSwitchToLOCFlow is true switch to locFlow
   * else return locFlow
   *
   * */
  return (getStepperConfig(settings) as StepProps[])
    .filter((step) => {
      // Exclude the entire Financial Information step if shouldHideFinancialInformation is true
      return !(
        step.name === StepName.FINANCIAL_INFORMATION &&
        shouldHideFinancialInformation
      );
    })
    .filter((step) => {
      return !(
        step.name === StepName.VENDOR_INFORMATION && !enableVendorScreen
      );
    })
    .map((step) => {
      step.subSteps = step.subSteps.filter((subStep) => {
        if (
          settings.disableLandingPage &&
          subStep.name === SubStepName.INITIAL_PAGE
        ) {
          return false;
        }
        if (
          (settings.hideLoanReasonsScreen || settings.disableLandingPage) &&
          subStep.name === SubStepName.LOAN_REASON
        ) {
          return false;
        }
        if (
          (settings.hideProductSelectionPage || settings.disableLandingPage) &&
          subStep.name === SubStepName.LOAN_TYPE
        ) {
          return false;
        }
        if (
          settings.hideEligibilityScreen &&
          subStep.name === SubStepName.COMPANY_INFORMATION_START_SCREEN
        ) {
          return false;
        }

        if (settings.hideStartLoanApplicationScreen) {
          if (subStep.name === SubStepName.PRE_APPLICATION_INFORMATION) {
            return false;
          }
        }

        // The below check will be true when we are not asking for any kind of financial document. But we have disbursement account to show ("disburementAccountProvided" flag is true).
        // So we can't skip the whole financial information step
        if (
          (disableBankStatementsUpload &&
            disableVATStatementsUpload &&
            disableSalesStatementsUpload &&
            subStep.name === SubStepName.ADDITIONAL_FINANCIAL_DOCUMENT) ||
          subStep.name === SubStepName.ADDITIONAL_INFORMATION
        ) {
          return false;
        }

        if (
          !disbursementAccountProvided &&
          subStep.name === SubStepName.Manual_Accounts
        ) {
          return false;
        }

        if (
          disbursementAccountProvided &&
          subStep.name === SubStepName.FINANCIAL_INFORMATION_START
        ) {
          return false;
        }

        if (
          !showMerchantIdStep &&
          subStep.name === SubStepName.MASTERCARD_MERCHANTID
        ) {
          return false;
        }

        return true;
      });

      return step;
    });
};

type Props = {
  children: ReactNode;
};

export const LoanApplicationStepper: React.FC<Props> = ({ children }) => {
  const {
    state: { settings },
  } = useDpConfig();

  const {
    state: { loanType: loanApplicationLoanType },
  } = useLoanApplication();
  const [loanType, setLoanType] = useState<LoanProductType | undefined>(
    undefined
  );

  const {
    shouldHideFinancialInformation,
    disableBankStatementsUpload,
    disableSalesStatementsUpload,
    disableVATStatementsUpload,
    disbursementAccountProvided,
    showMerchantIdStep,
    enableVendorScreen,
  } = useLoanProductConfigFlags(loanType);

  const stepConfig = useMemo(() => {
    return createConditionalStepConfig(
      settings,
      shouldHideFinancialInformation,
      disableBankStatementsUpload,
      disableSalesStatementsUpload,
      disableVATStatementsUpload,
      disbursementAccountProvided,
      showMerchantIdStep,
      enableVendorScreen
    );
  }, [
    settings,
    shouldHideFinancialInformation,
    disableBankStatementsUpload,
    disableSalesStatementsUpload,
    disableVATStatementsUpload,
    disbursementAccountProvided,
    showMerchantIdStep,
    enableVendorScreen,
  ]);

  useEffect(() => {
    if (loanApplicationLoanType !== loanType) {
      setLoanType(loanApplicationLoanType);
    }
  }, [loanApplicationLoanType, loanType]);

  return (
    <StepperContextProvider stepConfig={stepConfig}>
      {children}
    </StepperContextProvider>
  );
};

export const useStepper = () => useContext(StepperContext);
