import { FC, ReactNode, useState } from "react";
import { PlusLgIcon } from "../../assets/icons";
import clsx from "clsx";

export enum OpenIndicatorPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum AccordionVariant {
  CHILD_INSIDE = "CHILD_INSIDE",
  CHILD_OUTSIDE = "CHILD_OUTSIDE",
}

export type AccordionProps = {
  heading: ReactNode;
  children: ReactNode;
  openIndicatorPosition?: OpenIndicatorPosition;
  openIndicatorOverride?: ReactNode;
  accordionVariant?: AccordionVariant;
};

const Accordion: FC<AccordionProps> = ({
  heading,
  children,
  openIndicatorPosition = OpenIndicatorPosition.RIGHT,
  accordionVariant = AccordionVariant.CHILD_INSIDE,
  openIndicatorOverride,
}) => {
  const [open, setOpen] = useState(false);

  const openIndicator = (
    <div
      className={clsx(
        "cx-transition-[transform] cx-duration-500 cx-flex-shrink-0",
        {
          "cx-rotate-[135deg]": !openIndicatorOverride && open,
          "cx-rotate-90": openIndicatorOverride && open,
        }
      )}
    >
      {openIndicatorOverride || (
        <PlusLgIcon
          className={"cx-text-brand-primary-regular cx-w-6 cx-h-6 "}
        />
      )}
    </div>
  );

  if (accordionVariant === AccordionVariant.CHILD_OUTSIDE) {
    return (
      <div className={""}>
        <div className="cx-w-full cx-border cx-rounded-base cx-px-5 cx-py-3">
          <div
            className="cx-flex cx-w-full cx-justify-between cx-items-center cx-cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {openIndicatorPosition === OpenIndicatorPosition.LEFT &&
              openIndicator}
            {heading}
            {openIndicatorPosition === OpenIndicatorPosition.RIGHT &&
              openIndicator}
          </div>
        </div>
        <div
          className={clsx(
            "cx-rounded-base cx-overflow-hidden cx-transition-all cx-duration-5000 cx-ease-in-out",
            {
              "cx-border cx-max-h-96 cx-mt-6 cx-p-4": open, // Expanded state (visible)
              "cx-max-h-0 cx-border-0 cx-mt-0 cx-p-0": !open, // Collapsed state (fully hidden)
            }
          )}
        >
          <div
            className={
              "cx-text-text-secondary cx-transition-all cx-duration-1000 cx-ease-in-out"
            }
          >
            {children}
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className={""}>
        <div className="cx-w-full cx-border cx-rounded-base cx-px-5 cx-py-3">
          <div
            className="cx-flex cx-w-full cx-justify-between cx-items-center cx-cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {openIndicatorPosition === OpenIndicatorPosition.LEFT &&
              openIndicator}
            <div className="cx-text-text-primary cx-font-medium cx-pr-2">
              {heading}
            </div>
            {openIndicatorPosition === OpenIndicatorPosition.RIGHT &&
              openIndicator}
          </div>
          <div
            className={clsx(
              "cx-overflow-hidden cx-max-h-0 cx-transition-[max-height] cx-duration-500 cx-ease-in-out",
              {
                "cx-max-h-96 cx-overflow-scroll": open,
              }
            )}
          >
            <div className="cx-text-text-secondary cx-py-4">{children}</div>
          </div>
        </div>
      </div>
    );
};

export default Accordion;
