import { useId, useState } from "react";
import Select, { components, OptionProps } from "react-select";
import ClickAwayListener from "react-click-away-listener";
import { DropdownIcon } from "../../assets/icons";

type OptionType = {
  value: string;
  label: string;
};

type ComboboxProps = {
  options: Array<OptionType>;
  label?: string;
  error?: string;
  placeholder?: string;
  inputProps?: {
    name?: string;
    value?: OptionType;
    onBlur?: () => void;
    onInputChange?: (e: string) => void;
  };
  onChange?: (e: string) => void;
};

const Option = (props: OptionProps<OptionType>) => (
  <components.Option {...props}>
    <div className="hover:cx-bg-primary hover:cx-text-text-inverse cx-cursor-pointer cx-py-1 cx-px-4">
      {props.label}
    </div>
  </components.Option>
);

const Combobox = ({
  error,
  options,
  label,
  placeholder = "Select your option",
  inputProps = {},
  onChange,
}: ComboboxProps) => {
  const id = useId();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleInputChange = (inputValue: string) => {
    // Open the dropdown when typing
    if (inputValue.trim().length > 0) {
      setIsOpen(true);
    }
    // Trigger custom input change handler if provided
    if (inputProps.onInputChange) {
      inputProps.onInputChange(inputValue);
    }
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleMenuOpen = () => {
    setIsOpen(true);
  };

  const handleMenuClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div
          className={`cx-relative cx-w-full cx-rounded-base cx-border-2 cx-bg-background-default ${
            error
              ? "cx-border-stroke-error"
              : "cx-border-stroke-primary cx-focus-within:border-stroke-brand"
          }`}
        >
          {label && (
            <label
              htmlFor={id}
              className={`cx-absolute cx-text-xs cx-text-text-tertiary cx-px-4 cx-pt-2 cx-top-0`}
            >
              {label}
            </label>
          )}
          <Select
            id={id}
            unstyled
            isMulti={false}
            options={options}
            menuIsOpen={isOpen}
            placeholder={placeholder}
            classNames={{
              container: () =>
                `cx-relative cx-bg-transparent cx-appearance-none cx-outline-none ${
                  label ? "cx-pb-2 cx-pt-6" : "cx-pb-4 cx-pt-4"
                } cx-pl-4 cx-pr-10 cx-w-full cx-rounded-base`,
              menu: () =>
                "cx-bg-background-default cx--left-1 cx-border-2 cx-mt-1 cx-ml-1 cx-rounded cx-z-50",
              placeholder: () => "cx-text-text-tertiary",
            }}
            components={{
              Option,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            styles={{
              control: () => ({ minHeight: 0 }),
              menu: (styles) => ({ ...styles, zIndex: 100 }),
            }}
            {...inputProps}
            onFocus={handleFocus}
            onInputChange={handleInputChange} // Handle typing input
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
            onChange={(option) => {
              setIsOpen(false); // Close the dropdown when an option is selected
              if (onChange) {
                onChange(option?.value ?? "");
              }
            }}
          />
          <div className="cx-absolute cx-right-0 cx-top-0 cx-bottom-0 cx-py-4 cx-pr-4">
            <DropdownIcon height="100%" className={"cx-text-[#AAAAAA]"} />
          </div>
        </div>
      </ClickAwayListener>
      {error && (
        <div className="cx-text-xs cx-py-1 cx-text-text-error">{error}</div>
      )}
    </>
  );
};

export default Combobox;
