import { Button, Combobox, InputField, Modal } from "../../../components";
import { FC, useCallback, useEffect, useState } from "react";
import { getVendorTitle } from "./utils";
import {
  LoanProductType,
  LoanVendorType,
  LogoDevBrand,
  MediaPurpose,
  MediaType,
  Vendor,
} from "../../../hooks/api/types";
import { LoanApplication, useLoanApplication } from "../../../contexts";
import UploadMultipleDocs from "../BusinessInformation/UploadMultipleDocs";
import { FileObject } from "../BusinessInformation/service";
import countries from "world-countries";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  useCreateVendor,
  useDeleteMedia,
  useMedia,
  useSearchBrand,
  useUpdateLoanVendor,
} from "../../../hooks";
import { debounce } from "lodash";
import { CustomFile } from "../FinancialInformation/service";
import { ErrorIcon } from "../../../assets/icons";
import { clsx } from "clsx";

export enum AddVendorModalVariant {
  ALL_MANDATORY = "ALL_MANDATORY",
  ESSENTIALS_ONLY = "ESSENTIALS_ONLY",
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onAddVendor: (vendor?: Partial<Vendor>) => Promise<void>;
  vendorState?: Vendor;
  loanState?: LoanApplication;
  variant?: AddVendorModalVariant;
};

type VendorFormValues = {
  vendorName: string;
  country: string;
  tradeLicenseNumber?: string;
  tradeLicense?: FileObject[];
  invoices?: FileObject[];
  vatCertificates?: FileObject[];
  ibanCertificates?: FileObject[];
  additionalDocs?: FileObject[];
};

const VENDOR_INFORMATION_ALL_MANDATORY_VALIDATION_SCHEMA = yup.object().shape({
  vendorName: yup.string().required("Please enter vendor name"),
  country: yup.string().required("Please select country"),
  tradeLicenseNumber: yup
    .string()
    .required("Please enter trade license number"),
  tradeLicense: yup.array().min(1, "Please upload trade license"),
  invoices: yup.array().min(1, "Please upload invoices"),
  vatCertificates: yup.array().min(1, "Please upload VAT certificates"),
  ibanCertificates: yup.array().min(1, "Please upload IBAN certificates"),
  additionalDocs: yup.array().min(1, "Please upload additional documents"),
});

const VENDOR_INFORMATION_ONLY_ESSENTIALS_VALIDATION_SCHEMA = yup
  .object()
  .shape({
    vendorName: yup.string().required("Please enter vendor name"),
    country: yup.string().required("Please select country"),
    tradeLicenseNumber: yup.string(),
  });

const getCountryValue = (key: string) => {
  if (!key) return;
  const matchedCountry = countries?.find((country) => country.cca3 === key);
  return {
    value: matchedCountry?.cca3 || "",
    label: matchedCountry?.flag + " " + matchedCountry?.name.official,
  };
};

const VendorInformationModal: FC<Props> = ({
  isOpen,
  onClose,
  onAddVendor,
  vendorState,
  loanState,
  variant = AddVendorModalVariant.ESSENTIALS_ONLY,
}) => {
  const onlyEssential = variant === AddVendorModalVariant.ESSENTIALS_ONLY;

  const {
    state: { loanType, id: loanApplicationId },
  } = useLoanApplication();
  const { upload, get } = useMedia();
  const { mutateAsync: createVendor } = useCreateVendor();
  const { mutateAsync: updateLoanVendor } = useUpdateLoanVendor();
  const { mutateAsync: deleteMediaById } = useDeleteMedia();

  const [vendorTradeLicense, setVendorTradeLicense] = useState<
    FileObject[] | undefined
  >(undefined);
  const [invoices, setInvoices] = useState<FileObject[] | undefined>(undefined);
  const [vatCertificates, setVatCertificates] = useState<
    FileObject[] | undefined
  >(undefined);
  const [ibanCertificates, setIbanCertificates] = useState<
    FileObject[] | undefined
  >(undefined);
  const [additionalDocs, setAdditionalDocs] = useState<
    FileObject[] | undefined
  >(undefined);

  const [vendorList, setVendorList] = useState<Array<LogoDevBrand>>([]);
  const [vendorQuery, setVendorQuery] = useState<string>("");
  const { data, refetch } = useSearchBrand(vendorQuery);
  const [saving, setSaving] = useState(false);
  const [docsToDelete, setDocsToDelete] = useState<string[]>([]);
  const [error, setError] = useState<string | null>();

  const [tradeLicenseDocumentIds, setTradeLicenseDocumentIds] = useState<
    Set<string>
  >(new Set());
  const [ibanDocumentIds, setIbanDocumentIds] = useState<Set<string>>(
    new Set()
  );
  const [invoiceIds, setInvoiceIds] = useState<Set<string>>(new Set());
  const [vatDocumentIds, setVatDocumentIds] = useState<Set<string>>(new Set());
  const [additionalDocIds, setAdditionalDocIds] = useState<Set<string>>(
    new Set()
  );

  const handleRemoveDocument = async (
    fileName: string,
    documentList: FileObject[] | undefined,
    setDocumentList: React.Dispatch<React.SetStateAction<typeof documentList>>
  ) => {
    if (!documentList?.length) {
      return;
    }

    const removedDoc = documentList.find(
      (fileObj) => fileObj.file.name === fileName
    );
    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete((prev) => [...prev, removedDoc.id]);
    }
    const updatedList = documentList.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setDocumentList(updatedList);
  };

  const getMediaFromDocumentIds = async (documentIds: string[]) => {
    const retrievedDocs: FileObject[] = [];

    const promises = documentIds.map(async (documentId) => {
      try {
        const res = await get({ id: documentId });
        const file = new CustomFile(
          new File([], res.media.fileName),
          res.media.id
        );
        Object.defineProperty(file, "size", {
          value: res.media.size,
        });
        retrievedDocs.push({ id: res.media.id, file, uploaded: true });
      } catch (err) {
        console.log(err);
      }
    });
    await Promise.all(promises);
    return retrievedDocs;
  };

  const uploadDocuments = async (
    docs: Array<FileObject>,
    purpose: MediaPurpose
  ) => {
    const docUploadPromises = docs.map(async (doc) => {
      if (doc.uploaded) return doc.id;
      const id = await upload({
        file: doc.file,
        type: MediaType.DOCUMENT,
        purpose: purpose,
        loanApplicationId: loanState?.id || loanApplicationId,
      });
      doc.id = id;
      return id;
    });

    const uploadDocResponse = await Promise.all(docUploadPromises);

    let initialList: string[] = [];

    switch (purpose) {
      case MediaPurpose.VENDOR_TRADE_LICENSE: {
        initialList = vendorState?.tradeLicenseDocumentIds || [];
        break;
      }
      case MediaPurpose.VENDOR_IBAN_LETTER: {
        initialList = vendorState?.ibanCertificatesDocumentIds || [];
        break;
      }
      case MediaPurpose.VENDOR_INVOICE: {
        initialList = vendorState?.invoicesDocumentIds || [];
        break;
      }
      case MediaPurpose.VENDOR_VAT_STATEMENT: {
        initialList = vendorState?.vatCertificateDocumentIds || [];
        break;
      }
      case MediaPurpose.VENDOR_ADDITIONAL_DOCUMENT: {
        initialList = vendorState?.additionalDocumentIds || [];
        break;
      }
    }

    const finalList = initialList.filter(
      (docId) => !docsToDelete.includes(docId)
    );
    return Array.from(new Set([...finalList, ...uploadDocResponse]));
  };

  const setMediaFiles = async () => {
    const tradeLicenseDocumentIdsArray = Array.from(tradeLicenseDocumentIds);
    const ibanDocumentIdsArray = Array.from(ibanDocumentIds);
    const invoiceIdsArray = Array.from(invoiceIds);
    const vatDocumentIdsArray = Array.from(vatDocumentIds);
    const additionalDocIdsArray = Array.from(additionalDocIds);

    if (tradeLicenseDocumentIdsArray?.length) {
      const tradeLicenseMedia = await getMediaFromDocumentIds(
        tradeLicenseDocumentIdsArray
      );
      setVendorTradeLicense(tradeLicenseMedia);
    }
    if (ibanDocumentIdsArray?.length) {
      const ibanMedia = await getMediaFromDocumentIds(ibanDocumentIdsArray);
      setIbanCertificates(ibanMedia);
    }
    if (invoiceIdsArray?.length) {
      const invoiceMedia = await getMediaFromDocumentIds(invoiceIdsArray);
      setInvoices(invoiceMedia);
    }
    if (vatDocumentIdsArray?.length) {
      const vatMedia = await getMediaFromDocumentIds(vatDocumentIdsArray);
      setVatCertificates(vatMedia);
    }
    if (additionalDocIdsArray?.length) {
      const additionalDocMedia = await getMediaFromDocumentIds(
        additionalDocIdsArray
      );
      setAdditionalDocs(additionalDocMedia);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    submitForm,
    resetForm,
    setValues,
  } = useFormik<VendorFormValues>({
    validationSchema: onlyEssential
      ? VENDOR_INFORMATION_ONLY_ESSENTIALS_VALIDATION_SCHEMA
      : VENDOR_INFORMATION_ALL_MANDATORY_VALIDATION_SCHEMA,
    initialValues: {
      vendorName: "",
      country: "",
      tradeLicenseNumber: "",
      tradeLicense: undefined,
      invoices: undefined,
      vatCertificates: undefined,
      ibanCertificates: undefined,
      additionalDocs: undefined,
    },
    onSubmit: async (values) => {
      try {
        setSaving(true);

        const docToDeletePromises = docsToDelete.map((docId) => {
          return deleteMediaById(docId);
        });

        await Promise.all(docToDeletePromises);

        const vendor = {
          name: values.vendorName,
          country: values.country,
          licenseNumber: values.tradeLicenseNumber,
          tradeLicenseDocumentIds:
            vendorTradeLicense &&
            (await uploadDocuments(
              vendorTradeLicense,
              MediaPurpose.VENDOR_TRADE_LICENSE
            )),
          ibanDocumentIds:
            ibanCertificates &&
            (await uploadDocuments(
              ibanCertificates,
              MediaPurpose.VENDOR_IBAN_LETTER
            )),
          invoicesDocumentIds:
            invoices &&
            (await uploadDocuments(invoices, MediaPurpose.VENDOR_INVOICE)),
          vatCertificateDocumentIds:
            vatCertificates &&
            (await uploadDocuments(
              vatCertificates,
              MediaPurpose.VENDOR_VAT_STATEMENT
            )),
          additionalDocumentIds:
            additionalDocs &&
            (await uploadDocuments(
              additionalDocs,
              MediaPurpose.VENDOR_ADDITIONAL_DOCUMENT
            )),
          type:
            loanType === LoanProductType.LOC_PAYABLE_FINANCING
              ? LoanVendorType.SUPPLIER
              : LoanVendorType.BUYER,
          loanApplicationId: loanState?.id || loanApplicationId,
        };

        let createdVendor;
        if (vendorState?.vendorId) {
          await updateLoanVendor({
            ...vendor,
            loanVendorId: vendorState.loanVendorId,
          });
          createdVendor = {
            ...vendor,
            vendorId: vendorState.vendorId,
            loanVendorId: vendorState.id,
          };
        } else {
          createdVendor = await createVendor(vendor);
        }
        await onAddVendor(createdVendor);
        setSaving(false);
        handleClose();
      } catch (err: any) {
        setError(err?.message || "An error occurred");
        setSaving(false);
      }
    },
  });

  const debouncedSetVendorQuery = useCallback(
    debounce((query: string) => {
      setVendorQuery(query);
    }, 1000), // Adjust debounce delay (in milliseconds) as needed
    []
  );

  useEffect(() => {
    if (isOpen) {
      const tradeLicenses = new Set<string>();
      const ibanCertificates = new Set<string>();
      const additionalDocs = new Set<string>();
      const vatStatements = new Set<string>();
      const invoices = new Set<string>();

      vendorState?.documents?.forEach((doc) => {
        const docId = doc?.document?.id || doc.documentId;
        const purpose = doc.documentPurpose || doc?.document?.purpose;

        switch (purpose) {
          case MediaPurpose.VENDOR_TRADE_LICENSE:
            tradeLicenses.add(docId);
            break;
          case MediaPurpose.VENDOR_IBAN_LETTER:
            ibanCertificates.add(docId);
            break;
          case MediaPurpose.VENDOR_ADDITIONAL_DOCUMENT:
            additionalDocs.add(docId);
            break;
          case MediaPurpose.VENDOR_VAT_STATEMENT:
            vatStatements.add(docId);
            break;
          case MediaPurpose.VENDOR_INVOICE:
            invoices.add(docId);
            break;
        }
      });

      // Add additional document IDs if present
      vendorState?.tradeLicenseDocumentIds?.forEach((id) =>
        tradeLicenses.add(id)
      );
      vendorState?.ibanCertificatesDocumentIds?.forEach((id) =>
        ibanCertificates.add(id)
      );
      vendorState?.additionalDocumentIds?.forEach((id) =>
        additionalDocs.add(id)
      );
      vendorState?.vatCertificateDocumentIds?.forEach((id) =>
        vatStatements.add(id)
      );
      vendorState?.invoicesDocumentIds?.forEach((id) => invoices.add(id));

      // Update state
      setTradeLicenseDocumentIds(tradeLicenses);
      setInvoiceIds(invoices);
      setAdditionalDocIds(additionalDocs);
      setIbanDocumentIds(ibanCertificates);
      setVatDocumentIds(vatStatements);
    } else {
      setTradeLicenseDocumentIds(new Set());
      setInvoiceIds(new Set());
      setAdditionalDocIds(new Set());
      setIbanDocumentIds(new Set());
      setVatDocumentIds(new Set());
    }
  }, [
    isOpen,
    vendorState?.documents,
    vendorState?.tradeLicenseDocumentIds,
    vendorState?.ibanCertificatesDocumentIds,
    vendorState?.additionalDocumentIds,
    vendorState?.vatCertificateDocumentIds,
    vendorState?.invoicesDocumentIds,
  ]);

  useEffect(() => {
    setMediaFiles();
  }, [
    tradeLicenseDocumentIds,
    ibanDocumentIds,
    invoiceIds,
    additionalDocIds,
    vatDocumentIds,
  ]);

  useEffect(() => {
    const dataArray = [...(data || [])];
    dataArray.push({ name: vendorQuery });
    setVendorList(dataArray);
  }, [data]);

  useEffect(() => {
    if (vendorQuery.trim()) {
      refetch(); // Fetch vendors based on the query
    }
  }, [vendorQuery]);

  useEffect(() => {
    if (vendorState && isOpen && !error) {
      resetForm({
        values: {
          vendorName: vendorState.name,
          country: vendorState.country,
          tradeLicenseNumber: vendorState.licenseNumber,
        },
      });
    } else {
      resetModal();
    }
  }, [vendorState, isOpen]);

  useEffect(() => {
    //set form values
    setValues({
      ...values,
      tradeLicense: vendorTradeLicense,
      invoices: invoices,
      vatCertificates: vatCertificates,
      ibanCertificates: ibanCertificates,
      additionalDocs: additionalDocs,
    });
  }, [
    vendorTradeLicense,
    invoices,
    vatCertificates,
    ibanCertificates,
    additionalDocs,
  ]);

  //reset all states to initial state
  const resetModal = () => {
    resetForm();
    setVendorQuery("");
    setVendorList([]);
    setTradeLicenseDocumentIds(new Set([]));
    setInvoiceIds(new Set([]));
    setIbanDocumentIds(new Set([]));
    setVatDocumentIds(new Set([]));
    setAdditionalDocIds(new Set([]));

    setDocsToDelete([]);

    setVendorTradeLicense([]);
    setVatCertificates([]);
    setAdditionalDocs([]);
    setInvoices([]);
    setIbanCertificates([]);
  };

  const handleClose = () => {
    resetModal();
    setError(null);
    resetForm({
      values: {
        vendorName: "",
        country: "",
        tradeLicenseNumber: "",
        tradeLicense: undefined,
        invoices: undefined,
        vatCertificates: undefined,
        ibanCertificates: undefined,
        additionalDocs: undefined,
      },
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={`${getVendorTitle(
        (loanType || loanState?.loanType) as LoanProductType
      )} details`}
      className={"cx-max-w-[652px]"}
    >
      <div className={"cx-min-w-[600px]"}>
        {Boolean(error) && (
          <div className="cx-flex cx-content-center cx-justify-center cx-w-full cx-bg-error-lighter cx-rounded-base cx-mb-6">
            <div
              className={
                "cx-flex cx-flex-row cx-items-center cx-gap-2 cx-w-full cx-px-2 cx-py-2.5"
              }
            >
              <div className={"cx-w-5 cx-h-5"}>
                <ErrorIcon className={""} />
              </div>
              <div className={"cx-font-medium cx-text-s"}>{error}</div>
            </div>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div
            className={clsx("cx-flex cx-flex-row cx-gap-2 cx-w-full", {
              "cx-flex-col cx-gap-0": onlyEssential,
            })}
          >
            <div className={"cx-mb-6 cx-w-full"}>
              <Combobox
                placeholder={
                  getVendorTitle(
                    (loanType || loanState?.loanType) as LoanProductType
                  ) + " Name *"
                }
                options={vendorList.map((vendor) => {
                  return {
                    value: vendor.name,
                    label: vendor.name,
                  };
                })}
                error={
                  touched.vendorName && errors.vendorName
                    ? errors.vendorName
                    : ""
                }
                onChange={handleChange("vendorName")}
                inputProps={{
                  name: "vendorName",
                  value: values.vendorName
                    ? {
                        value: values.vendorName,
                        label: values.vendorName,
                      }
                    : undefined,
                  onInputChange: (query) => {
                    debouncedSetVendorQuery(query); // Update query for fetching options
                  },
                }}
              />
            </div>
            <div className={"cx-mb-6 cx-w-full"}>
              <Combobox
                placeholder={"Country of Registration *"}
                options={countries.map((country) => {
                  return {
                    value: country.cca3,
                    label: country.flag + " " + country.name.official,
                  };
                })}
                onChange={handleChange("country")}
                error={touched.country && errors.country ? errors.country : ""}
                inputProps={{
                  name: "country",
                  value: getCountryValue(values.country),
                }}
              />
            </div>
          </div>
          <InputField
            label={"Trade license number"}
            optional={onlyEssential}
            className={"cx-mb-6"}
            inputProps={{
              name: "tradeLicenseNumber",
              value: values.tradeLicenseNumber,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={
              touched.tradeLicenseNumber && errors.tradeLicenseNumber
                ? errors.tradeLicenseNumber
                : ""
            }
            tick={Boolean(values.tradeLicenseNumber)}
          />
          <UploadMultipleDocs
            label={"Trade License"}
            setDocuments={setVendorTradeLicense}
            documents={vendorTradeLicense || []}
            handleFileClose={async (name) => {
              await handleRemoveDocument(
                name,
                vendorTradeLicense,
                setVendorTradeLicense
              );
            }}
            type={MediaPurpose.VENDOR_TRADE_LICENSE}
            enableDropzone={onlyEssential}
            lightCards={!onlyEssential}
            error={touched.tradeLicense ? errors.tradeLicense : ""}
          />
          {!onlyEssential && (
            <div className={"cx-flex cx-flex-col"}>
              <UploadMultipleDocs
                label={"Invoices"}
                setDocuments={setInvoices}
                documents={invoices || []}
                handleFileClose={async (name) => {
                  await handleRemoveDocument(name, invoices, setInvoices);
                }}
                type={MediaPurpose.VENDOR_INVOICE}
                enableDropzone={false}
                lightCards={true}
                error={touched.invoices ? errors.invoices : ""}
              />
              <UploadMultipleDocs
                label={"VAT certificate"}
                setDocuments={setVatCertificates}
                documents={vatCertificates || []}
                handleFileClose={async (name) => {
                  await handleRemoveDocument(
                    name,
                    vatCertificates,
                    setVatCertificates
                  );
                }}
                type={MediaPurpose.VENDOR_VAT_STATEMENT}
                enableDropzone={false}
                lightCards={true}
                error={touched.vatCertificates ? errors.vatCertificates : ""}
              />
              <UploadMultipleDocs
                label={"IBAN certificate"}
                setDocuments={setIbanCertificates}
                documents={ibanCertificates || []}
                handleFileClose={async (name) => {
                  await handleRemoveDocument(
                    name,
                    ibanCertificates,
                    setIbanCertificates
                  );
                }}
                type={MediaPurpose.VENDOR_IBAN_LETTER}
                enableDropzone={false}
                lightCards={true}
                error={touched.ibanCertificates ? errors.ibanCertificates : ""}
              />
              <UploadMultipleDocs
                label={"Additional documents"}
                setDocuments={setAdditionalDocs}
                documents={additionalDocs || []}
                handleFileClose={async (name) => {
                  await handleRemoveDocument(
                    name,
                    additionalDocs,
                    setAdditionalDocs
                  );
                }}
                type={MediaPurpose.VENDOR_ADDITIONAL_DOCUMENT}
                enableDropzone={false}
                lightCards={true}
                error={touched.additionalDocs ? errors.additionalDocs : ""}
              />
            </div>
          )}
          <div className="cx-mt-6">
            <Button
              label={vendorState ? "Update" : "Add"}
              fullWidth={true}
              type={"submit"}
              disabled={saving}
              loader={saving ? "right" : undefined}
              onClick={submitForm}
            />
            <Button
              label="Cancel"
              fullWidth={true}
              secondary={true}
              onClick={handleClose}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default VendorInformationModal;
