import { useContext, useState } from "react";
import { useFormik } from "formik";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../contexts";
import { Button, CardList, Dropzone, StepNextButton } from "../../components";
import {
  LOAN_UPLOAD_SALES_INVOICE_VALIDATION_SCHEMA,
  LOAN_UPLOAD_SALES_INVOICES_INITIAL_VALUES,
  SALES_INVOICE_CONFIG,
  UploadSalesInvoiceFields,
} from "./service";
import { ArrowLeftIcon, PdfIcon } from "../../assets/icons";
import {
  CardVariantType,
  DisplayVariant,
} from "../../components/CardList/CardList";
import { formatBytes } from "../../utils";
import { useDpConfig } from "../../contexts/dpConfig";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { useMedia } from "../../hooks";

const UploadSalesInvoice = ({ closeModal }: { closeModal: () => void }) => {
  const [salesInvoices, setSalesInvoices] = useState<File[]>([]);
  const [customErrors, setCustomErrors] = useState<{ [key: string]: string }>(
    {}
  );
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { buyNowPayLaterLoan, id },
    actions: { update },
  } = useLoanApplication();

  const {
    state: { buyNowPayLaterLoan: buyNowPayLaterLoanCalculatorState },
  } = useLoanCalculator();

  const { upload } = useMedia();

  const {
    state: { uiLocalizationData },
  } = useDpConfig();

  const { previousStep, nextStep } = useContext(StepperContext);
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();

  async function handleBuyNowPayLaterLoanUpdate(
    id: string,
    salesInvoiceFiles: File[]
  ) {
    const salesInvoicePromises = salesInvoiceFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.INVOICE,
        loanApplicationId: id,
      })
    );
    const salesInvoiceIds = await Promise.all(salesInvoicePromises);
    const salesInvoices = salesInvoiceIds.map((_invoice, index) => ({
      invoiceId: salesInvoiceIds[index],
    }));
    await update({
      id,
      loanType: LoanProductType.BNPL,
      buyNowPayLaterLoan: {
        ...buyNowPayLaterLoanCalculatorState,
        salesInvoices: [...salesInvoices],
      },
    });
  }

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm } =
    useFormik<UploadSalesInvoiceFields>({
      initialValues: {
        ...LOAN_UPLOAD_SALES_INVOICES_INITIAL_VALUES,
        salesInvoices: buyNowPayLaterLoan.salesInvoices.map(
          (salesInvoice) => new File([], salesInvoice.invoiceId + ".pdf")
        ),
      },
      validationSchema: LOAN_UPLOAD_SALES_INVOICE_VALIDATION_SCHEMA,
      onSubmit: async (values) => {
        try {
          await handleBuyNowPayLaterLoanUpdate(id, values.salesInvoices);
          closeModal();
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleUpload = (files: File[]) => {
    if (!files || files.length === 0) return;
    if (files.length > 1) {
      setCustomErrors({
        multipleFileError: "Cannot upload more than one document",
      });
      return;
    }
    const file = files[0];
    setCustomErrors({});

    const isFileAlreadyAdded = values.salesInvoices.some(
      (existingFile) => existingFile.name === file.name
    );
    if (!isFileAlreadyAdded) {
      const updatedInvoices = [file];

      setUploading(true);
      setFieldValue("salesInvoices", updatedInvoices);
      setSalesInvoices(updatedInvoices);
      setUploading(false);
    }
  };

  const handleFileClose = async (fileName: string) => {
    setSalesInvoices((prev) => {
      return prev.filter((salesInvoice) => fileName !== salesInvoice.name);
    });
    values.salesInvoices = values.salesInvoices.filter(
      (saleInvoice) => fileName !== saleInvoice.name
    );
    validateForm();
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-4 cx-max-w-[960px]">
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-gap-2">
        <div className="cx-text-3xl cx-font-bold cx-text-center">
          {uiLocalizationData.invoicePage.dropBoxLabel}
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mt-4">
          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: SALES_INVOICE_CONFIG.accept,
              maxSize: SALES_INVOICE_CONFIG.maxSize, // 100MB to byte
            }}
            onDrop={async (files) => {
              if (!files) return;
              handleUpload(files);
            }}
          />
          {customErrors.multipleFileError && (
            <p className="cx-text-error-regular cx-text-sm cx-mt-2">
              {customErrors.multipleFileError}
            </p>
          )}
        </div>
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
            {salesInvoices.map((salesInvoice: File, index: number) => (
              <div className="cx-mb-2">
                <CardList
                  key={index}
                  variant={CardVariantType.UPLOADED}
                  title={salesInvoice.name}
                  description={
                    touched.salesInvoices &&
                    values.salesInvoices.length &&
                    !!errors.salesInvoices?.[index]
                      ? (errors.salesInvoices[index] as string)
                      : formatBytes(salesInvoice.size, 2)
                  }
                  fullWidth
                  icon={
                    <PdfIcon
                      width="100%"
                      height="100%"
                      className="cx-text-brand-primary-regular"
                    />
                  }
                  inputProps={{ accept: ".pdf,application/pdf" }}
                  error={
                    touched.salesInvoices &&
                    !!errors.salesInvoices?.[index] &&
                    !!values.salesInvoices.length
                  }
                  handleFileClose={handleFileClose}
                  truncateTitleLength={35}
                  displayVariant={DisplayVariant.NEW}
                />
              </div>
            ))}
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton
              type="button"
              label="Next"
              loading={uploading || loading}
              disabled={uploading || loading || !values.salesInvoices?.length}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadSalesInvoice;
