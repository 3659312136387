import {
  FileObject,
  MOA_CONFIG,
  POA_CONFIG,
  TRADE_LICENSE_CONFIG,
  VENDOR_DOCUMENT_CONFIG,
} from "./service";
import {
  CardVariantType,
  DisplayVariant,
} from "../../../components/CardList/CardList";
import { Button, CardList, Divider, Dropzone } from "../../../components";
import { DocumentIcon, PdfIcon } from "../../../assets/icons";
import { MediaPurpose, VendorMediaPurpose } from "../../../hooks/api/types";
import { formatBytes } from "../../../utils";
import { useDropzone } from "react-dropzone";
import { clsx } from "clsx";

type Props = {
  label: string;
  setDocuments: (docs: FileObject[]) => void;
  documents: FileObject[];
  handleFileClose: (fileName: string, docId?: string) => void;
  uploadedDocs?: string[];
  type: MediaPurpose;
  className?: string;
  enableDropzone?: boolean;
  lightCards?: boolean;
  error?: string;
};

export const UploadMultipleDocs: React.FC<Props> = ({
  label,
  documents,
  setDocuments,
  handleFileClose,
  type,
  className,
  enableDropzone = true,
  lightCards = false,
  error,
}) => {
  let config;
  if (type === MediaPurpose.TRADE_LICENSE) {
    config = MOA_CONFIG;
  } else if (type === MediaPurpose.POWER_OF_ATTORNEY) {
    config = POA_CONFIG;
  } else if (Object.values(VendorMediaPurpose).map(String).includes(type)) {
    config = VENDOR_DOCUMENT_CONFIG;
  } else {
    config = TRADE_LICENSE_CONFIG; // Default configuration
  }

  const handleUpload = async (files: File[]) => {
    if (!files) return;

    const updatedDocs = [...documents];

    files.forEach((file: File) => {
      let isFileAlreadyAdded = updatedDocs.some(
        (existingFile) => existingFile.file.name === file.name
      );
      if (!isFileAlreadyAdded) {
        updatedDocs.push({ id: "", file });
      }
    });

    setDocuments([...updatedDocs]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      if (!files) return;
      handleUpload(files);
    },
    // extensions: {["PDF only", "Maximum file size is 5 MB"]},
    accept: config.accept,
    maxFiles: config.maxFiles,
    maxSize: config.maxSize, // 100MB to byte
  });

  return (
    <div className={"cx-mb-5"}>
      <div
        className={`cx-bg-white cx-border cx-border-gray-300 cx-rounded-lg cx-px-4 cx-pt-4 ${className}`}
      >
        <div className="cx-flex cx-justify-between cx-items-center">
          <div className={"cx-flex cx-flex-row cx-gap-1"}>
            <div className="cx-w-6 cx-h-6 cx-shrink-0 cx-text-brand-primary-regular">
              <DocumentIcon width="100%" height="100%" />
            </div>
            <p className="cx-text-base cx-pl-2">{label}</p>
          </div>
          {!enableDropzone && (
            <div {...getRootProps()}>
              <Button
                label={"Upload"}
                slim={true}
                className={`cx-py-1 cx-text-s`}
              />
              <input {...getInputProps()} />
            </div>
          )}
        </div>
        <div className="cx-mt-4">
          {enableDropzone ? (
            <Dropzone
              variant="simple"
              extensions={["PDF only", "Maximum file size is 5 MB"]}
              options={{
                accept: config.accept,
                maxFiles: config.maxFiles,
                maxSize: config.maxSize, // 100MB to byte
              }}
              onDrop={async (files) => {
                if (!files) return;
                handleUpload(files);
              }}
            />
          ) : (
            Boolean(documents.length) && <Divider />
          )}
        </div>

        <div
          className={clsx(
            "cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg",
            {
              "cx-my-2": lightCards,
            }
          )}
        >
          {documents.map((st, index: number) => (
            <div className="cx-mb-2" key={index}>
              <CardList
                variant={CardVariantType.UPLOADED}
                title={st.file.name || type}
                fullWidth
                icon={
                  <PdfIcon
                    width="100%"
                    height="100%"
                    className="cx-text-brand-primary-regular"
                  />
                }
                inputProps={{ accept: ".pdf,application/pdf" }}
                truncateTitleLength={35}
                displayVariant={
                  lightCards ? DisplayVariant.LITE : DisplayVariant.NEW
                }
                handleFileClose={handleFileClose}
                description={st?.file?.size && formatBytes(st.file.size, 2)}
              />
              {lightCards && documents.length - 1 !== index && <Divider />}
            </div>
          ))}
        </div>
      </div>
      {error && (
        <div className={"cx-text-xs cx-text-text-error cx-mt-1"}>{error}</div>
      )}
    </div>
  );
};

export default UploadMultipleDocs;
