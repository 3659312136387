import { FC, useEffect, useState } from "react";
import { Button, Divider, InputField, Slider } from "../../components";
import { useLoanApplication, useLoanCalculator } from "../../contexts";
import { useFormik } from "formik";
import {
  BuyNowPayLaterCalculatorFields,
  getBuyNowPayLaterLoanValidationSchema,
} from "./service";
import {
  convertBnplMonthsToInstallments,
  convertInstallmentsToBnplMonths,
  getFutureDate,
  roundTo,
} from "../../utils";
import { useStepper } from "../LoanApplicationSteps/Stepper";
import { ArrowLeftIcon } from "../../assets/icons";
import { useLoanMetrics, useMedia } from "../../hooks";
import Transaction from "./Transaction";
import {
  LoanApplicationStage,
  LoanDurationType,
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import TermsAndConditions from "./TermsAndConditions";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

type Props = {
  editMode?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
};

const BuyNowPayLaterCalculator: FC<Props> = ({
  editMode = false,
  handleSubmit: handleSubmitFromParent,
  handleCancel: handleCancelFromParent,
}) => {
  const [isPartnerTermsAccepted, setIsPartnerTermsAccepted] =
    useState<boolean>();

  //need to use a state because of the offset between calculatorState and formik values
  const [initialDuration, setInitialDuration] = useState<number>(0);
  const {
    state: { buyNowPayLaterLoan, constraints },
    actions: { setBuyNowPayLaterLoan },
  } = useLoanCalculator();

  const {
    state: { dpTermsUrl },
  } = useDpConfig();
  const {
    state: { id, salesInvoiceFiles, sourceId, loanType },
    actions: { update },
  } = useLoanApplication();

  const { enableVendorScreen } = useLoanProductConfigFlags(loanType);

  const { nextStep } = useStepper();
  const { totalPayment, buyNowPayLaterPaymentPerMonth } = useLoanMetrics();
  const { upload } = useMedia();
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();
  const { disableSalesInvoiceUpload } = useLoanProductConfigFlags(
    LoanProductType.BNPL
  );

  useEffect(() => {
    const { min, initial } = constraints.buyNowPayLaterLoan.duration;
    if (!buyNowPayLaterLoan.duration) {
      setBuyNowPayLaterLoan({
        ...buyNowPayLaterLoan,
        duration: initial || min,
      });
    }
    //on first render set the initial duration for the form to the
    // initial duration (originally in months) to duration in installments that
    // is (durationInMonths + 1) order of precedence is
    // 1. duration from loan state if it exists
    // 2. initial duration from constraints if it exists
    // 3. min duration from constraints
    setInitialDuration(
      convertBnplMonthsToInstallments(
        buyNowPayLaterLoan?.duration || initial || min
      )
    );
  }, [buyNowPayLaterLoan.duration]);

  const { values, errors, setFieldValue, handleSubmit } =
    useFormik<BuyNowPayLaterCalculatorFields>({
      enableReinitialize: true,
      initialValues: {
        loanAmount:
          buyNowPayLaterLoan?.amount ||
          constraints.buyNowPayLaterLoan?.amount?.initial,
        duration: initialDuration,
      },
      validationSchema: getBuyNowPayLaterLoanValidationSchema({
        loanAmount: {
          min: constraints.buyNowPayLaterLoan?.amount?.min,
          max: constraints.buyNowPayLaterLoan?.amount?.max,
          unit: constraints.buyNowPayLaterLoan?.amount?.unit ?? "AED",
        },
        duration: {
          //we need to add 1 to the min and max duration because the
          // duration is in months and needs to be translated to installments
          min:
            constraints.buyNowPayLaterLoan?.duration?.min &&
            convertBnplMonthsToInstallments(
              constraints.buyNowPayLaterLoan?.duration?.min
            ),
          max:
            constraints.buyNowPayLaterLoan?.duration?.max &&
            convertBnplMonthsToInstallments(
              constraints.buyNowPayLaterLoan?.duration?.max
            ),
        },
      }),
      onSubmit: async () => {
        //if loc flow is enabled, we need to update the loan application and move to the next step
        if (!disableLOCFlow) {
          setBuyNowPayLaterLoan({
            amount: values.loanAmount,
            //we need to subtract 1 from the duration because the duration is in installments
            // and needs to be translated to months
            duration: convertInstallmentsToBnplMonths(values.duration),
            durationType: constraints.buyNowPayLaterLoan.duration
              .unit as LoanDurationType,
            interestRate: constraints.buyNowPayLaterLoan.interestRate,
            salesInvoices: [],
          });
          await handleBuyNowPayLaterLoanUpdate(id);

          let nextStage = LoanApplicationStage.OWNER_INFORMATION;
          if (sourceId) {
            nextStage = LoanApplicationStage.DOCUMENTS_UPLOAD;
          }
          await update({
            stage: nextStage,
          });
          return;
        }
        nextStep();
      },
    });

  //if loan application doesnt exist yet, set the loan amount to the initial value
  useEffect(() => {
    if (!id) {
      setBuyNowPayLaterLoan({
        ...buyNowPayLaterLoan,
        amount: values.loanAmount,
        //we need to subtract 1 from the duration because the duration is in installments
        // and needs to be translated to months
        duration: convertInstallmentsToBnplMonths(values.duration),
        interestRate: constraints.buyNowPayLaterLoan.interestRate,
        durationType: constraints.buyNowPayLaterLoan.duration
          .unit as LoanDurationType,
      });
    }
  }, [id]);

  async function handleBuyNowPayLaterLoanUpdate(id: string) {
    const salesInvoicePromises = salesInvoiceFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.INVOICE,
        loanApplicationId: id,
      })
    );
    const salesInvoiceIds = await Promise.all(salesInvoicePromises);
    const salesInvoices = salesInvoiceIds.map((_invoice, index) => ({
      invoiceId: salesInvoiceIds[index],
    }));
    await update({
      id,
      loanType: LoanProductType.BNPL,
      buyNowPayLaterLoan: {
        amount: values.loanAmount,
        //we need to subtract 1 from the duration because the duration is in installments
        duration: convertInstallmentsToBnplMonths(values.duration),
        durationType: constraints.buyNowPayLaterLoan.duration
          .unit as LoanDurationType,
        interestRate: constraints.buyNowPayLaterLoan.interestRate,
        salesInvoices: [...salesInvoices],
      },
    });
  }

  const handleInputChange = (name: string, value: string) => {
    const num = Number(value.replaceAll(",", ""));
    if (isNaN(num)) {
      return null;
    }
    setFieldValue(name, num, true);
    if (
      num > constraints.buyNowPayLaterLoan.amount.max ||
      num < constraints.buyNowPayLaterLoan.amount.min
    ) {
      return null;
    }
    return num;
  };

  const handleLoanAmountInputChange = (value: string) => {
    const val = handleInputChange("loanAmount", value);
    if (val !== null) {
      setBuyNowPayLaterLoan({
        ...buyNowPayLaterLoan,
        amount: val,
        interestRate: constraints.buyNowPayLaterLoan.interestRate,
      });
    }
  };

  const handleLoanAmountSliderChange = (value: number) => {
    setFieldValue("loanAmount", value);
    setBuyNowPayLaterLoan({
      ...buyNowPayLaterLoan,
      amount: value,
      interestRate: constraints.buyNowPayLaterLoan.interestRate,
    });
  };

  const handleDurationInputChange = (value: string) => {
    const val = handleInputChange("duration", value);
    if (val !== null) {
      setBuyNowPayLaterLoan({
        ...buyNowPayLaterLoan,
        //we need to subtract 1 from the duration because the duration is in installments
        // and needs to be translated to months
        duration: convertInstallmentsToBnplMonths(val),
        interestRate: constraints.buyNowPayLaterLoan.interestRate,
      });
    }
  };

  const handleDurationSliderChange = (value: number) => {
    setFieldValue("duration", value);
    setBuyNowPayLaterLoan({
      ...buyNowPayLaterLoan,
      //we need to subtract 1 from the duration because the duration is in installments
      // and needs to be translated to months
      duration: convertInstallmentsToBnplMonths(value),
      interestRate: constraints.buyNowPayLaterLoan.interestRate,
    });
  };

  const generatePaymentSchedule = (): string[] => {
    const initialDate = getFutureDate(2, "days");
    const totalInstallments = convertBnplMonthsToInstallments(
      buyNowPayLaterLoan.duration
    );
    return Array.from({ length: totalInstallments }, (_, index) => {
      const date =
        index === 0
          ? initialDate
          : getFutureDate(1, "months", initialDate.plus({ months: index - 1 }));
      return date.toFormat("dd MMM yyyy");
    });
  };

  const handleTermsChange = (checked: boolean) => {
    setIsPartnerTermsAccepted(checked);
  };

  const hideDurationInputContainer = (): boolean => {
    const { min, max } = constraints.buyNowPayLaterLoan.duration;
    return min !== max;
  };

  return (
    <div className="cx-w-full cx-flex cx-flex-col cx-p-10 cx-gap-10 cx-items-center cx-rounded-modal cx-border-0.5 cx-border-[#DDD] cx-shadow-modal cx-background-calculator-gradient">
      <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
        <div className="cx-font-medium">Total repayment amount</div>
        <div className="cx-text-3xl cx-font-bold">
          {constraints.buyNowPayLaterLoan?.amount?.unit}{" "}
          {roundTo(totalPayment, 2).toLocaleString()}
        </div>
        <div className="cx-text-sm cx-text-text-secondary">
          Pay as low as{" "}
          {roundTo((buyNowPayLaterLoan.interestRate / 12) * 100, 2)}% monthly
          interest across {values.duration} installments*.
        </div>
      </div>
      <Divider className="cx-display-none" />
      <div className="cx-flex cx-flex-row cx-w-full cx-gap-6">
        <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
          <div className="cx-font-semibold">Payment amount</div>
          <div>
            <InputField
              inputProps={{
                name: "loanAmount",
                value: values.loanAmount?.toLocaleString(),
                onChange: (e) => handleLoanAmountInputChange(e.target.value),
              }}
              inputClassName="cx-text-right !cx-p-2 cx-font-bold"
              prepend={constraints.buyNowPayLaterLoan?.amount?.unit}
              error={errors.loanAmount}
              hideErrorMessage
            />
          </div>
          <div className="">
            <Slider
              initial={values.loanAmount}
              min={constraints.buyNowPayLaterLoan?.amount?.min}
              max={constraints.buyNowPayLaterLoan?.amount?.max}
              unit={constraints.buyNowPayLaterLoan?.amount?.unit}
              value={values.loanAmount}
              label=""
              onChange={(value) => handleLoanAmountSliderChange(value)}
              renderThumb={false}
              interval={1000}
            />
          </div>
          {errors.loanAmount && (
            <div className="cx-text-xs cx-text-text-error">
              {errors.loanAmount}
            </div>
          )}
        </div>
        {hideDurationInputContainer() && (
          <div className="cx-flex cx-flex-col cx-gap-4 cx-w-full">
            <div className="cx-font-semibold">No. of Installments</div>
            <div>
              <InputField
                inputProps={{
                  name: "duration",
                  value: values.duration,
                  onChange: (e) => handleDurationInputChange(e.target.value),
                }}
                inputClassName="cx-text-right !cx-p-2 cx-font-bold"
                prepend="Installments"
              />
            </div>
            <div className="">
              <Slider
                initial={values.duration}
                //we need to add 1 to the min and max duration because the
                // duration is in months and needs to be translated to installments
                min={
                  constraints.buyNowPayLaterLoan?.duration?.min &&
                  convertBnplMonthsToInstallments(
                    constraints.buyNowPayLaterLoan?.duration?.min
                  )
                }
                max={
                  constraints.buyNowPayLaterLoan?.duration?.max &&
                  convertBnplMonthsToInstallments(
                    constraints.buyNowPayLaterLoan?.duration?.max
                  )
                }
                unit={"Installment"}
                value={values.duration}
                label=""
                onChange={(value) => handleDurationSliderChange(value)}
                renderThumb={false}
              />
            </div>
            {errors.duration && (
              <div className="cx-text-xs cx-text-text-error">
                {errors.duration}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="cx-container cx-flex cx-gap-2">
        <p className="cx-text-base cx-text-black">Payment schedule</p>
      </div>
      <div className="cx-w-full">
        {generatePaymentSchedule().map((date, index) => (
          <Transaction
            key={index}
            installmentNo={index}
            isLast={index === buyNowPayLaterLoan.duration + 1}
            isFirst={index === 0}
            monthlyPayable={buyNowPayLaterPaymentPerMonth}
            currencyUnit={constraints.buyNowPayLaterLoan.amount.unit}
            date={date}
            isReviewScreen={false}
          />
        ))}
      </div>
      <div className="cx-w-[400px]">
        {editMode ? (
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <div className="cx-w-1/4">
              <Button
                label={<ArrowLeftIcon />}
                type="button"
                onClick={handleCancelFromParent}
                fullWidth
                outlined
              />
            </div>
            <div className="cx-w-3/4">
              <Button
                type="button"
                label="Confirm"
                fullWidth
                onClick={handleSubmitFromParent}
                disabled={Object.keys(errors).length > 0}
              />
            </div>
          </div>
        ) : (
          <Button
            label="Continue"
            arrow="right"
            fullWidth
            onClick={handleSubmit}
            disabled={
              Object.keys(errors).length > 0 ||
              (!isPartnerTermsAccepted && !!dpTermsUrl)
            }
          />
        )}
      </div>
      <Divider />
      <div className="cx-block">
        <TermsAndConditions
          onTermsChange={handleTermsChange}
          loanType={LoanProductType.BNPL}
        />
      </div>
    </div>
  );
};

export default BuyNowPayLaterCalculator;
