import { ReactNode } from "react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ButtonLoaderIcon,
} from "../../assets/icons";

export type Props = {
  onClick?: () => void;
  label?: string | ReactNode;
  outlined?: boolean;
  danger?: boolean;
  arrow?: "left" | "right"; // should be placement
  invertArrowDirection?: boolean;
  arrowClassName?: string;
  disabled?: boolean;
  slim?: boolean;
  loader?: "left" | "right";
  fullWidth?: boolean;
  type?: "submit" | "button";
  secondary?: boolean;
  bgTransparent?: boolean;
  className?: string;
};

const Button = ({
  onClick,
  label,
  arrow,
  invertArrowDirection,
  arrowClassName,
  disabled,
  slim,
  outlined,
  loader,
  fullWidth,
  type = "button",
  secondary,
  danger,
  className,
  bgTransparent,
}: Props) => {
  let btnStyle =
    "cx-bg-interaction-button-primary-default cx-text-interaction-button-text-inverse hover:cx-bg-interaction-button-primary-hover focus:cx-bg-interaction-button-primary-pressed focus:cx-ring-2 cx-rounded-btn";
  if (outlined) {
    btnStyle =
      "cx-bg-interaction-button-primary-inverse cx-text-interaction-button-text-default cx-border cx-border-stroke-primary hover:cx-bg-interaction-button-primary-hover hover:cx-text-white focus:cx-bg-interaction-button-primary-pressed focus:cx-text-white focus:cx-ring-2 cx-rounded-btn";
  }
  if (secondary) {
    btnStyle = `${
      bgTransparent ? "" : "cx-bg-interaction-button-text-inverse"
    } cx-text-interaction-button-text-default cx-border-stroke-primary`;
  }
  if (danger) {
    btnStyle =
      "cx-bg-background-error cx-text-interaction-button-text-inverse hover:cx-bg-interaction-button-danger-hover focus:cx-bg-interaction-button-danger-pressed focus:cx-ring-2 cx-rounded-btn";
  }
  if (disabled) {
    btnStyle =
      "cx-bg-interaction-button-primary-disabled cx-text-interaction-button-text-disabled cx-rounded-btn";
  }
  let paddingStyle = "";
  if (loader && slim) {
    paddingStyle = label ? "cx-px-4" : "!cx-p-2";
  }
  const arrowStyle = "cx-mr-2 " + arrowClassName;
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${fullWidth ? "cx-w-full" : ""} ${btnStyle} ${
        slim ? "cx-px-4 cx-py-2" : "cx-px-6 cx-py-4"
      } ${paddingStyle} cx-flex cx-flex-row cx-justify-center cx-items-center ${className}`}
    >
      {arrow === "left" &&
        (invertArrowDirection ? (
          <ArrowLeftIcon className={arrowStyle} />
        ) : (
          <ArrowRightIcon className={arrowStyle} />
        ))}
      {loader === "left" && (
        <ButtonLoaderIcon
          className={`cx-animate-spin cx-h-5 cx-w-5 ${label ? "cx-mr-2" : ""}`}
        />
      )}
      <div>{label}</div>
      {loader === "right" && (
        <ButtonLoaderIcon
          className={`cx-animate-spin cx-h-5 cx-w-5 ${label ? "cx-ml-2" : ""}`}
        />
      )}
      {arrow === "right" &&
        (invertArrowDirection ? (
          <ArrowLeftIcon className={arrowStyle} />
        ) : (
          <ArrowRightIcon className={arrowStyle} />
        ))}
    </button>
  );
};

export default Button;
