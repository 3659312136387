import { useEffect, useState } from "react";
import { ButtonLoaderIcon, PlusIcon } from "../../../assets/icons";
import CompanyProfileCard from "./CompanyProfileCard";
import { useLoansSummary } from "../../../hooks";
import { useLoanApplication } from "../../../contexts";
import { useStepper } from "../Stepper";
import { useDpConfig } from "../../../contexts/dpConfig";

const GlobalLoader = () => {
  return (
    <div className="cx-w-full cx-h-screen cx-flex cx-justify-center cx-items-center cx-text-brand-primary-regular">
      <ButtonLoaderIcon className="cx-animate-spin cx-w-6 cx-h-6" />
    </div>
  );
};

type CompanyProfile = {
  companyId: string;
  loanApplicationId: string;
  name: string;
  licensingAuthority: string;
  licenseNumber: string;
};

type Props = {};

const SelectCompanyProfile: React.FC<Props> = () => {
  const [companyProfiles, setCompanyProfiles] = useState<Array<CompanyProfile>>(
    []
  );
  const [shouldSkip, setShouldSkip] = useState(false);

  const {
    actions: { update },
  } = useLoanApplication();
  const {
    state: { products, settings },
  } = useDpConfig();
  const { nextStep, gotoStep } = useStepper();

  const { mutateAsync: getCompanyProfiles, isLoading } = useLoansSummary();

  useEffect(() => {
    getCompanyProfiles()
      .then((res) => {
        if (res?.flags?.isFirstLoanApplication) {
          setShouldSkip(true);
        } else if (res?.uniqueCompanyProfiles?.length) {
          setCompanyProfiles(res.uniqueCompanyProfiles);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (shouldSkip) {
      nextStep();
    }
  }, [shouldSkip]);

  const handleStartLoanApplication = async (loanApplicationId?: string) => {
    if (loanApplicationId) {
      await update({ sourceId: loanApplicationId }, { local: true });
    }
    // IMP: It is important to update right loanType here in case of 1 product because by default it is set to FIXED_TERM
    if (products.length === 1) {
      const [product] = products;
      await update({ loanType: product.type }, { local: true });
    }

    !settings.disableLandingPage && nextStep(); // lands on SubStepName.INITIAL_PAGE
    nextStep(); // skips SubStepName.INITIAL_PAGE and lands on SubStepName.PHONE_NUMBER
  };

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <div className="cx-flex cx-flex-col cx-items-center cx-w-full">
      <div className="cx-text-text-primary cx-font-bold cx-text-3xl cx-text-center cx-mb-10">
        Apply for a new loan
        <p className="cx-text-text-secondary cx-font-light cx-text-sm">
          Select company to proceed with your loan application
        </p>
      </div>
      <div className="cx-max-w-[400px] cx-flex cx-flex-col cx-gap-6 cx-w-full cx-justify-center">
        {companyProfiles.map((profile) => (
          <div
            key={profile.loanApplicationId}
            className="cx-w-[400px] cx-flex-col"
            onClick={() => {
              handleStartLoanApplication(profile.loanApplicationId);
            }}
          >
            <CompanyProfileCard
              name={profile.name}
              licenseNumber={profile.licenseNumber}
              licensingAuthority={profile.licensingAuthority}
            />
          </div>
        ))}
      </div>
      <div className="cx-max-w-[400px] cx-w-full cx-my-6 cx-cursor-pointer">
        <div
          className="cx-w-full cx-flex cx-flex-row cx-gap-x-2"
          onClick={() => {
            handleStartLoanApplication();
          }}
        >
          <PlusIcon className="cx-text-brand-primary-regular" />
          <p className="cx-text-sm cx-text-brand-primary-regular cx-leading-3 cx-pt-0.5">
            Add a new company
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectCompanyProfile;
