import { Dropdown, InputField } from "../../../components";
import {
  BusinessInformationFormFields,
  DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS,
} from "./service";
import { FormikErrors, FormikTouched, FormikHandlers } from "formik";

interface OfficeAddressFormProps {
  values: BusinessInformationFormFields;
  errors: FormikErrors<BusinessInformationFormFields>;
  touched: FormikTouched<BusinessInformationFormFields>;
  handleBlur: FormikHandlers["handleBlur"];
  handleChange: FormikHandlers["handleChange"];
}

export const OfficeAddressForm: React.FC<OfficeAddressFormProps> = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) => {
  return (
    <>
      <div className="cx-font-semibold cx-text-text-primary cx-text-base cx-mb-4">
        Office address
      </div>
      <div className="cx-flex cx-justify-between cx-mb-4">
        <div className="cx-mr-1 cx-w-full">
          <Dropdown
            label="Emirate"
            options={DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS}
            inputProps={{
              name: "emirate",
              value: values.emirate,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.emirate && !!errors.emirate ? errors.emirate : ""}
          />
        </div>
        <div className="cx-ml-1 cx-w-full">
          <InputField
            label="Area"
            tick={touched.area && !errors.area}
            inputProps={{
              name: "area",
              value: values.area,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.area && !!errors.area ? errors.area : ""}
          />
        </div>
      </div>
      <div className="cx-flex cx-justify-between cx-mb-4">
        <div className="cx-mr-1 cx-w-full">
          <InputField
            label="Street name"
            tick={touched.street && !errors.street}
            inputProps={{
              name: "street",
              value: values.street,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.street ? errors.street : ""}
          />
        </div>
        <div className="cx-ml-1 cx-w-full">
          <InputField
            label="Building"
            tick={touched.building && !errors.building}
            inputProps={{
              name: "building",
              value: values.building,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.building ? errors.building : ""}
          />
        </div>
      </div>
      <div className="cx-flex cx-justify-between cx-mb-10">
        <div className="cx-mr-1 cx-w-full">
          <InputField
            label="Floor"
            tick={touched.floor && !errors.floor}
            inputProps={{
              name: "floor",
              value: values.floor,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.floor ? errors.floor : ""}
          />
        </div>
        <div className="cx-ml-1 cx-w-full">
          <InputField
            label="Office number"
            tick={touched.number && !errors.number}
            inputProps={{
              name: "number",
              value: values.number,
              onChange: handleChange,
              onBlur: handleBlur,
            }}
            error={touched.number ? errors.number : ""}
          />
        </div>
      </div>
    </>
  );
};
