import { LoanProductType } from "../../../hooks/api/types";

export const getVendorNature = (loanType: LoanProductType): string => {
  return loanType === LoanProductType.LOC_PAYABLE_FINANCING
    ? "supplier"
    : "buyer";
};

export const getVendorTitle = (loanType: LoanProductType): string => {
  const vendorNature = getVendorNature(loanType);
  return vendorNature.charAt(0).toUpperCase() + vendorNature.slice(1);
};
