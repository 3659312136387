import React, { FC } from "react";
import { Modal } from "../../../components";
import ApplicantInformationForm from "./ApplicantInformationForm";
import { Applicant } from "../../../contexts";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: Applicant) => Promise<void>;
  onCancel: () => void;
};

const ApplicantInformationModal: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  onCancel,
}) => {
  return (
    <Modal title="" isOpen={isOpen} onClose={onClose} className="cx-py-4">
      <ApplicantInformationForm onSubmit={onSubmit} shareholderExists={false} />
    </Modal>
  );
};

export default ApplicantInformationModal;
