import { Modal } from "../../../components";
import { UploadDocumentItem } from "./UploadDocumentItem";
import { useState } from "react";
import GenericDocumentUpload from "../FinancialInformation/GenericDocumentUpload";
import { useLoanApplication } from "../../../contexts/loanApplication";

export const AdditionalDocuments = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    state: { docGeneric },
  } = useLoanApplication();

  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <div className="cx-text-text-primary cx-font-medium cx-text-lg cx-mb-4">
        Other documents
      </div>
      <div className="cx-grid cx-gap-4 cx-grid-cols-1 md:cx-grid-cols-2 cx-w-full">
        <div className="cx-col-span-1 md:cx-col-span-2">
          <UploadDocumentItem
            title="Additional documents"
            description="Please upload additional documents if any"
            isOptional={true}
            onClick={() => setIsOpen(true)}
            uploadedDocsCount={docGeneric.length}
          />
        </div>
      </div>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <GenericDocumentUpload closeModal={closeModal} />
      </Modal>
    </div>
  );
};
