import React, { FC, useEffect, useState } from "react";
import { FileObject } from "../../LoanApplicationSteps/BusinessInformation/service";
import { useDeleteLoanVendorDocument, useMedia } from "../../../hooks";
import { MediaPurpose, MediaType, Vendor } from "../../../hooks/api/types";
import { Button, Modal } from "../../../components";
import UploadMultipleDocs from "../../LoanApplicationSteps/BusinessInformation/UploadMultipleDocs";
import { CustomFile } from "../../LoanApplicationSteps/FinancialInformation/service";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  documents: FileObject[];
  setDocuments: (docs: FileObject[]) => void;
  loanApplicationId: string;
  vendors?: Array<Vendor>;
  onSuccess?: (docList: Array<string>) => Promise<void>;
};

const AddVendorBankStatementModal: FC<Props> = ({
  isOpen,
  onClose,
  loanApplicationId,
  documents,
  setDocuments,
  vendors,
  onSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [docsToDelete, setDocsToDelete] = useState<string[]>([]);
  const [docsToUpload, setDocsToUpload] = useState<FileObject[]>([]);

  const { mutateAsync: deleteDocument } = useDeleteLoanVendorDocument();
  const { upload, get } = useMedia();

  useEffect(() => {
    if (isOpen && !docsToUpload?.length) {
      setDocsToUpload(documents);
    }
  }, [isOpen, documents]);

  const handleSubmit = async () => {
    setLoading(true);

    const docToDeletePromises = docsToDelete.map((docId) => {
      const vendorsPossessingThisDoc = vendors?.filter((vendor) => {
        return vendor?.loanVendorDocumentRequests?.filter((request) => {
          return request.loanVendorDocuments.some(
            (doc) => doc.documentId === docId
          );
        }).length;
      });

      vendorsPossessingThisDoc?.map(async (vendor) => {
        return await deleteDocument({
          loanApplicationId,
          loanVendorId: vendor.loanVendorId,
          documentId: docId,
        });
      });
    });

    await Promise.all(docToDeletePromises);

    const uploads = await Promise.all(
      docsToUpload.map(async (doc) => {
        if (doc.uploaded) return doc.id;
        const id = await upload({
          file: doc.file,
          type: MediaType.DOCUMENT,
          purpose: MediaPurpose.BANK_STATEMENT,
          loanApplicationId: loanApplicationId,
        });
        doc.id = id;
        return id;
      })
    );

    const leftOvers = uploads.filter((docId) => !docsToDelete.includes(docId));

    onSuccess && (await onSuccess(leftOvers));

    const files = await Promise.all(
      leftOvers.map(async (id) => {
        const media = await get({ id: id });

        const file = new CustomFile(
          new File([], media.media.fileName),
          media.media.id
        );
        Object.defineProperty(file, "size", {
          value: media.media.size,
        });
        return { id: media.media.id, file, uploaded: true };
      })
    );

    setDocuments([...files]);
    setLoading(false);
    onClose();
  };

  const handleFileClose = async (fileName: string) => {
    const removedDoc = documents.find(
      (fileObj) => fileObj.file.name === fileName
    );
    if (!removedDoc) {
      return;
    }
    if (removedDoc.id.length > 0) {
      setDocsToDelete([...docsToDelete, removedDoc.id]);
    }
    const updatedList = documents.filter(
      (fileObj) => fileObj.file.name !== fileName
    );
    setDocsToUpload(updatedList);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      title={`Upload bank statements`}
      className={"cx-pb-4"}
    >
      <div className={"cx-flex cx-flex-col cx-gap-4"}>
        <UploadMultipleDocs
          label={`Upload bank statements`}
          setDocuments={setDocsToUpload}
          documents={docsToUpload}
          handleFileClose={handleFileClose}
          type={MediaPurpose.BANK_STATEMENT}
        />
        <Button
          label={"Update"}
          fullWidth
          slim
          loader={loading ? "right" : undefined}
          disabled={loading}
          onClick={handleSubmit}
        />
        <Button
          label={"Cancel"}
          fullWidth
          secondary
          onClick={() => {
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default AddVendorBankStatementModal;
