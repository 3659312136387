import { LoanApplication, useLoanDashboard } from "../../../contexts";
import { FC, useMemo } from "react";
import {
  AdditionalInfoType,
  LoanApplicationStatus,
  LoanApplicationStatusLabels,
} from "../../../hooks/api/types";
import { Card } from "../../../components";
import LoanCardActions from "./LoanCardActions";
import LoanDetails from "./LoanDetails";
import { clsx } from "clsx";
import { DateTime } from "luxon";
import { roundTo } from "../../../utils";
import { useDpConfig } from "../../../contexts/dpConfig";

type LoanCardProps = {
  loan: LoanApplication;
  index: number;
};

export const LoanCard: FC<LoanCardProps> = ({ loan, index }) => {
  const {
    actions: { update },
  } = useLoanDashboard();
  const {
    state: { products },
  } = useDpConfig();

  const readableStatus = useMemo(() => {
    if (
      loan.additionalInfo?.every(
        (info: any) => info.type === AdditionalInfoType.SHAREHOLDERS_AECB
      )
    ) {
      return LoanApplicationStatusLabels[LoanApplicationStatus.PREAPPROVED];
    } else {
      return LoanApplicationStatusLabels[loan.status];
    }
  }, [loan]);

  const productConfig = products?.find(
    (product) => product.id === loan.loanProductId
  );

  return (
    <Card
      key={`loan-${index}`}
      title={index ? undefined : "Your loans"}
      className={
        productConfig?.config?.enableVendorScreen ? "cx-cursor-pointer" : ""
      }
    >
      <div
        onClick={async () => {
          productConfig?.config?.enableVendorScreen &&
            (await update({
              currentLoanApplicationId: loan.id,
              showLoanDetails: true,
            }));
        }}
      >
        <div className="cx-w-full sm:cx-flex cx-flex-row cx-items-center cx-px-6 cx-pb-4 cx-border-b-0.5 cx-border-neutral-light">
          <div className="cx-grow cx-flex cx-flex-row cx-items-center">
            <div className="cx-text-text-primary cx-font-semibold cx-text-lg cx-mr-4">
              {loan?.loanProduct?.displayName || "-"}
            </div>
            <div
              className={clsx(
                "cx-py-1 cx-px-3 cx-rounded-btn",
                readableStatus?.displayClasses || ""
              )}
            >
              {readableStatus?.label || "-"}
            </div>
          </div>
          <div className="sm:cx-flex cx-flex-row cx-items-center cx-gap-x-2">
            <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
              {`Loan #${loan.externalId}`}
            </div>
            {/* <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
                    <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                      {`${roundTo(
                        (loanInput?.interestRate / 12) * 100,
                        2
                      )}% Interest`}
                    </div> */}
            <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />

            {loan.loanOfferInterestRate && (
              <div className="sm:cx-flex cx-flex-row cx-items-center cx-gap-x-2 ">
                <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                  {`${roundTo(loan.loanOfferInterestRate / 12, 2)} % Interest`}
                </div>
                <div className="cx-hidden sm:cx-block cx-w-1 cx-h-1 cx-rounded-btn cx-bg-neutral-light" />
              </div>
            )}

            {!!loan?.submittedAt && (
              <div className="cx-text-text-secondary cx-font-medium cx-text-sm">
                Applied on{" "}
                {DateTime.fromISO(loan.submittedAt).toFormat("dd LLL yyyy")}
              </div>
            )}
          </div>
        </div>
        <LoanDetails loan={loan} />
        <LoanCardActions loan={loan} />
      </div>
    </Card>
  );
};

export default LoanCard;
