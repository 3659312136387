import { useContext, useState } from "react";
import { useFormik } from "formik";
import {
  StepperContext,
  useLoanApplication,
  useLoanCalculator,
} from "../../contexts";
import { CardList, Dropzone, StepNextButton } from "../../components";
import {
  LOAN_UPLOAD_POS_STATEMENTS_INITIAL_VALUES,
  LOAN_UPLOAD_POS_SALES_STATEMENT_VALIDATION_SCHEMA,
  POS_SALES_STATEMENT_CONFIG,
  UploadPosStatementsFields,
} from "./service";
import { PdfIcon } from "../../assets/icons";
import {
  CardVariantType,
  DisplayVariant,
} from "../../components/CardList/CardList";
import { formatBytes } from "../../utils";
import {
  LoanProductType,
  MediaPurpose,
  MediaType,
} from "../../hooks/api/types";
import { useMedia } from "../../hooks";
import { useDpConfig } from "../../contexts/dpConfig";
import { useLoanProductConfigFlags } from "../../hooks/useLoanProductConfigFlags";

const UploadPosSalesStatement = ({
  closeModal,
}: {
  closeModal: () => void;
}) => {
  const [posSalesStatements, setPosSalesStatements] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    state: { posRevenueBasedLoan: posRevenueBasedLoanCalculatorState },
  } = useLoanCalculator();
  const {
    state: { posRevenueBasedLoan, id, loanType },
    actions: { update },
  } = useLoanApplication();
  const { upload } = useMedia();

  const { nextStep } = useContext(StepperContext);
  const { disableSalesInvoiceUpload } = useLoanProductConfigFlags(loanType);
  const {
    state: {
      settings: { disableLOCFlow },
    },
  } = useDpConfig();

  async function handlePosRevenueBasedLoanUpdate(
    id: string,
    posSalesStatementFiles: File[]
  ) {
    const posSalesStatementPromises = posSalesStatementFiles.map((file) =>
      upload({
        file,
        type: MediaType.DOCUMENT,
        purpose: MediaPurpose.POS_SALES_STATEMENT,
        loanApplicationId: id,
      })
    );
    const posSalesStatementIds = await Promise.all(posSalesStatementPromises);
    const posSalesStatements = posSalesStatementIds.map(
      (_statement, index) => ({
        posStatementId: posSalesStatementIds[index],
      })
    );
    await update(
      {
        id,
        loanType: LoanProductType.POS_REVENUE_BASED_FINANCING,
        posRevenueBasedLoan: {
          ...posRevenueBasedLoanCalculatorState,
          posSalesStatements: posSalesStatements,
        },
      },
      {
        local: disableLOCFlow,
      }
    );
  }

  const { errors, touched, values, handleSubmit, setFieldValue, validateForm } =
    useFormik<UploadPosStatementsFields>({
      initialValues: {
        ...LOAN_UPLOAD_POS_STATEMENTS_INITIAL_VALUES,
        posSalesStatements: posRevenueBasedLoan.posSalesStatements.map(
          (posSalesStatement) =>
            new File([], posSalesStatement.posStatementId + ".pdf")
        ),
      },
      validationSchema: LOAN_UPLOAD_POS_SALES_STATEMENT_VALIDATION_SCHEMA,
      onSubmit: async (values) => {
        try {
          //if we are in loc flow mode
          if (!disableLOCFlow) {
            setLoading(true);
            await handlePosRevenueBasedLoanUpdate(
              id,
              values.posSalesStatements
            );
            setLoading(false);
          } else {
            //if legacy flow
            await update(
              {
                salesStatementFiles: values.posSalesStatements,
              },
              { local: true }
            );
            !disableSalesInvoiceUpload && nextStep();
            nextStep();
          }
          closeModal();
        } catch (err) {
          console.log(err);
        }
      },
    });

  const handleUpload = (files: File[]) => {
    if (!files) return;

    const updatedPosStatements = [...values.posSalesStatements];

    files.forEach((file: File) => {
      let isFileAlreadyAdded = updatedPosStatements.some(
        (existingFile) => existingFile.name === file.name
      );
      if (!isFileAlreadyAdded) {
        updatedPosStatements.push(file);
      }
    });
    setUploading(true);
    setFieldValue("posSalesStatements", [...updatedPosStatements]);
    setPosSalesStatements([...updatedPosStatements]);
    setUploading(false);
  };

  const handleFileClose = async (fileName: string) => {
    setPosSalesStatements((prev) => {
      return prev.filter(
        (posSalesStatement) => fileName !== posSalesStatement.name
      );
    });
    values.posSalesStatements = values.posSalesStatements.filter(
      (posSaleStatement) => fileName !== posSaleStatement.name
    );
    await validateForm();
  };

  return (
    <div className="cx-grow cx-flex cx-flex-col cx-items-center cx-justify-center cx-w-full cx-p-8 cx-max-w-[960px]">
      <div className="cx-flex cx-flex-col cx-justify-center cx-items-center cx-gap-2">
        <div className="cx-text-3xl cx-font-bold cx-text-center">
          Upload POS sales statement
        </div>
        <div className="cx-text-text-secondary cx-text-center">
          Please upload the sales statement for the last 12 months from your
          e-commerce platform
        </div>
      </div>
      <div className="cx-w-full cx-max-w-[400px]">
        <div className="cx-mt-4">
          <Dropzone
            variant={uploading ? "uploading" : "simple"}
            extensions={["PDF only", "Maximum file size is 5 MB"]}
            options={{
              accept: POS_SALES_STATEMENT_CONFIG.accept,
              maxFiles: POS_SALES_STATEMENT_CONFIG.maxFiles,
              maxSize: POS_SALES_STATEMENT_CONFIG.maxSize, // 100MB to byte
            }}
            onDrop={async (files) => {
              if (!files) return;
              handleUpload(files);
            }}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="cx-w-full cx-bg-background-default cx-my-10 cx-rounded-lg">
            {posSalesStatements.map(
              (posSalesStatement: File, index: number) => (
                <div className="cx-mb-2">
                  <CardList
                    variant={CardVariantType.UPLOADED}
                    title={posSalesStatement.name}
                    description={
                      touched.posSalesStatements &&
                      values.posSalesStatements.length &&
                      !!errors.posSalesStatements?.[index]
                        ? (errors.posSalesStatements[index] as string)
                        : formatBytes(posSalesStatement.size, 2)
                    }
                    fullWidth
                    icon={
                      <PdfIcon
                        width="100%"
                        height="100%"
                        className="cx-text-brand-primary-regular"
                      />
                    }
                    inputProps={{ accept: ".pdf,application/pdf" }}
                    error={
                      touched.posSalesStatements &&
                      !!errors.posSalesStatements?.[index] &&
                      !!values.posSalesStatements.length
                    }
                    handleFileClose={handleFileClose}
                    truncateTitleLength={35}
                    displayVariant={DisplayVariant.NEW}
                  />
                </div>
              )
            )}
          </div>
          <div className="cx-w-full cx-flex cx-flex-row cx-gap-x-4">
            <StepNextButton
              type="button"
              label="Next"
              loading={loading}
              disabled={
                uploading || loading || !values.posSalesStatements?.length
              }
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadPosSalesStatement;
