import * as yup from "yup";

export type InformationFormFields = {
  tradeLicenseNumber: string;
  annualRevenue: number;
  lastYearPaidTax: number;
  taxRegistrationNumber: string;
  licensingAuthorityId: string;
};

export type DetailFormFields = {
  companyName: string;
  companyYearEstabilished: string;
  businessType: string;
  industryTypeId: string;
  emirate: string;
  area: string;
  street: string;
  building: string;
  floor: string;
  number: string;
};

export type DocumentsUploadFormFields = {
  isSole: boolean;
  tradeLicense: File | null;
  memorandumOfAssociation: File | null;
  powerOfAttorney: File | null;
};

export type LicensingAuthorityFormField = {
  label: string;
  value: string;
};

export type FileObject = {
  uploaded?: boolean;
  id: string;
  file: File;
};

export const INFORMATION_FORM_INITIAL_VALUES: InformationFormFields = {
  tradeLicenseNumber: "",
  annualRevenue: 10000,
  lastYearPaidTax: 0,
  taxRegistrationNumber: "",
  licensingAuthorityId: "",
};

export const DETAIL_FORM_INITIAL_VALUES: DetailFormFields = {
  companyName: "",
  companyYearEstabilished: "",
  businessType: "",
  industryTypeId: "",
  emirate: "",
  area: "",
  street: "",
  building: "",
  floor: "",
  number: "",
};

export type BusinessInformationFormFields = InformationFormFields &
  DetailFormFields;

export const BUSINESS_INFORMATION_FORM_INITIAL_VALUES: BusinessInformationFormFields =
  {
    tradeLicenseNumber: "",
    annualRevenue: 10000,
    lastYearPaidTax: 0,
    taxRegistrationNumber: "",
    licensingAuthorityId: "",
    companyName: "",
    companyYearEstabilished: "",
    businessType: "",
    industryTypeId: "",
    emirate: "",
    area: "",
    street: "",
    building: "",
    floor: "",
    number: "",
  };

export const DOCUMENTS_UPLOAD_FORM_INITIAL_VALUES: DocumentsUploadFormFields = {
  isSole: false,
  tradeLicense: null,
  memorandumOfAssociation: null,
  powerOfAttorney: null,
};

export const DETAIL_FORM_BUSINESS_TYPE_DROPDOWN_OPTIONS = [
  "Sole Proprietorship",
  "Partnership",
  "Establishments",
  "Limited Liability Companies",
  "Free Zone Companies",
  "Free Zone Establishments",
  "Free Zone Offshore Companies",
  "UAE Governments Bodies",
  "UAE Governments Entities",
  "Charities Foundations",
  "Association Commitee Social Club",
  "Embassy Diplomatic Mission",
  "Educational Institutions",
];

export const DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS = [
  "Abu Dhabi",
  "Ajman",
  "Dubai",
  "Fujairah",
  "Ras Al Khaimah",
  "Sharjah",
  "Umm Al Quwain",
];

export const getEffectiveBusinessInformationFormValidationSchema = (
  disableRevenueHistory: boolean
) => {
  const informationValidationSchema = disableRevenueHistory
    ? INFORMATION_FORM_VALIDATION_SCHEMA
    : INFORMATION_FORM_VALIDATION_SCHEMA.shape({
        annualRevenue: yup
          .number()
          .integer("Annual revenue must be a an integer")
          .min(10000, "Annual revenue must be greater than AED 10,000")
          .required("Annual revenue is required"),
        taxRegistrationNumber: yup.string().when("annualRevenue", {
          is: (annualRevenue: number) => {
            return annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
          },
          then: (schema) =>
            schema
              .required("Tax registration number is required")
              .matches(
                /^\d+$/,
                "Tax registration number must only contain numbers"
              )
              .length(15, "Tax registration number must have 15 numbers"),
        }),
        lastYearPaidTax: yup
          .number()
          .when("annualRevenue", {
            is: (annualRevenue: number) => {
              return annualRevenue >= ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER;
            },
            then: (schema) =>
              schema
                .min(1000, "Tax paid must be greater than AED 1,000")
                .required("Last year's tax payment information is required"),
          })
          .test(
            "lastYearPaidTax",
            "Last year's tax payment must be less than annual revenue",
            (lastYearPaidTax, { parent: { annualRevenue } }) => {
              return !(lastYearPaidTax && lastYearPaidTax > annualRevenue);
            }
          ),
      });

  return informationValidationSchema.concat(DETAIL_FORM_VALIDATION_SCHEMA);
};

export const INFORMATION_FORM_VALIDATION_SCHEMA = yup.object().shape({
  tradeLicenseNumber: yup
    .string()
    .max(30, "Trade license cannot be longer than 30 characters")
    .required("Trade license number is required"),
  licensingAuthorityId: yup
    .string()
    .required("Licensing authority is required"),
});

export const DETAIL_FORM_VALIDATION_SCHEMA = yup.object().shape({
  companyName: yup.string().required("Company name is required"),
  companyYearEstabilished: yup
    .string()
    .required("Year established is required")
    .matches(/^\d{4}$/, "Year established must be in YYYY format"),
  businessType: yup.string().required("Business type is required"),
  industryTypeId: yup.string().required("Industry type is required"),
  emirate: yup
    .string()
    .required("Emirate is required")
    .oneOf(
      DETAIL_FORM_EMIRATE_DROPDOWN_OPTIONS,
      "Please select a valid emirate"
    ),
  area: yup.string().required("Area is required"),
  street: yup.string().required("Street is required"),
  building: yup.string().required("Building is required"),
  floor: yup.string().required("Floor is required"),
  number: yup.string().required("Number is required"),
});

export const DOCUMENTS_UPLOAD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  isSole: yup.boolean(),
  tradeLicense: yup.mixed().required("Trade license document is required"),
  memorandumOfAssociation: yup.mixed().when("isSole", {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      schema.required("Memorandum of association document is required"),
  }),
  powerOfAttorney: yup.mixed().when("isSole", {
    is: true,
    then: (schema) => schema.notRequired(),
    otherwise: (schema) =>
      schema.required("Power of attorney document is required"),
  }),
});

export const ANNUAL_REVENUE_REQUIRED_FOR_TAX_NUMBER = Number(
  import.meta.env.VITE_SKIP_VAT_LIMIT ?? 375000
);
export const TAX_PERCENTAGE_MULTIPLIER: number = 0.05;
export const INITIAL_YEAR = 1900;
export const DETAIL_FORM_YEAR_DROPDOWN_OPTIONS = Array.from(
  { length: 100 },
  (_, i) => {
    const year = (new Date().getFullYear() - i).toString();
    return {
      value: year,
      label: year,
    };
  }
);

export const VENDOR_DOCUMENT_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const TRADE_LICENSE_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const MOA_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};

export const POA_CONFIG = {
  accept: { "application/pdf": [".pdf"] },
  maxFiles: 10,
  minFiles: 1,
  maxSize: 100 * 1024 * 1024,
};
